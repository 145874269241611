import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react"
import { Store } from "@Store";
import { getProducts } from '@Utils/api'
import Loading from '@Components/layout/loading'
import MainShopping from "@Components/shopping/main";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const view = {
  title: <FormattedMessage id="view.title.shopping" defaultMessage="Shopping"/>,
  shadow: false,
  left_visible: true
}

const View = () => {
  const { id } = useParams()
  const [isLoading, setloading] = useState(!Store.getProduct(id).length)

  useEffect(() => {
    Store.setNavbar(view)

    const fetchProducts = async () => {
      await getProducts(id)
      await Store.todoList().load()
      setloading(false)
    }
    
    fetchProducts()
  }, [id])

  return (
    <Container fluid className="main-content-container px-3 pt-3">
      <Row className="mb-4">
        <Col lg="8">
          { isLoading ? <Loading /> : <MainShopping id={id} /> }
        </Col>
      </Row>
    </Container>
  )
}

export default View
