const types = {
    SET_NAVBAR: "SET_NAVBAR",

    SHOW_ALERT: "SHOW_ALERT",
    HIDE_ALERT: "HIDE_ALERT",

    REDIRECT: "REDIRECT",
    GOTO: "GOTO",

    SHOW_MODAL: "SHOW_MODAL",
    HIDE_MODAL: "HIDE_MODAL",

    SET_OFFLINE: "SET_OFFLINE",

    SET_LANG: "SET_LANG",
    SET_COMPATIBLE: "SET_COMPATIBLE",

    SET_SESSION: "SET_SESSION",
    REMOVE_SESSION: "REMOVE_SESSION",

    SET_ERROR: "SET_ERROR",


}

export default types