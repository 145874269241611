import { useCallback, useContext } from 'react'
import { api } from '@Config/config'
import { axiosRequest } from '@Utils/axios'
import { AppStore , AppTypes} from '@Contexts/app'
import JwtDecode from 'jwt-decode'
import useApp from './useApp'

const { passport, shopping } = api

const endpoints = {
    auth: {
        login: `${passport}/v2/auth/login`,
        refresh_token: `${passport}/v2/auth/refresh-token`
    },
    orders: {
        orders: `${shopping}/v2/orders?view=pwa`,
        address: `${shopping}/v2/user-address`,
        products: `${shopping}/v2/orders`, //      /<id>/details
        checkout: `${shopping}/v2/orders/checkout-review`
    }
}

const useRequest = () => {
    // console.log('START USE_REQUEST')
    const { showAlert } = useApp()
    const appContext = useContext(AppStore)
    const appState = appContext.state
    const appDispatch = appContext.dispatch

    let attemps = 0

    // events
    const refreshToken = async () => {
        try {
            const payload = {
                method: 'post',
                url: endpoints.auth.refresh_token,
                data: { refresh_token: appState?.session?.refresh_token },
                headers: { Authorization: `Bearer ${appState?.session?.token}` }
            }
    
            const session = (await axiosRequest(payload)).data
            console.log(session)

             // get token decode
             let sessionPayload = JwtDecode(session.token)
             sessionPayload = {...sessionPayload, ...session}
 
             // set to localstorage
             localStorage.setItem('auth', JSON.stringify(sessionPayload))
 
             // dispatch
             appDispatch({type: AppTypes.SET_SESSION, payload: sessionPayload})

             return session
             
        } catch (err) {
            console.log(err)
            showAlert('INVALID_CREDENTIALS')
        }
    }

    // interceptors
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const interceptor = async (err) => {
        console.log('INTERCEPTOR')
        console.log(attemps)

        if (err.response.status === 401) {
            console.log(err.response)
            if (attemps < 3) {
                attemps++
                const x = await refreshToken()
                console.log(x)
            }
        }
    }

    // requests
    const request = useCallback(async (payload) => {
        try {
            //eslint-disable-next-line react-hooks/exhaustive-deps
            attemps = 0
            console.log(attemps)
            payload.headers = { Authorization: `Bearer ${appState?.session?.token}` }

            const result = await axiosRequest(payload, interceptor)
            console.log(result)

            return result.data
        
        } catch (err) {
            console.log('ERR', err)
        }
    }, [appState, interceptor])

    return {
        request,
        endpoints
    }
}

export default useRequest