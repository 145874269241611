import React, { useContext } from 'react'
import { Card, CardBody, CardTitle, Badge, CardFooter, Button } from 'shards-react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'
import useApp from '@Hooks/useApp'
import useOrders from '@Hooks/useOrders'
import { FormattedMessage } from 'react-intl'

const OrderCard = ({item}) => {
    console.log(item)
    const { setGoto, showModal } = useApp()
    const { updateOrderStatus } = useOrders()
    const AppState = useContext(AppStore)
    const order = item.cardView()
    const todo = item.todo

    console.log(item.todo)
    console.log(item.id)

    const onStartShopping = (e) => {
        e.stopPropagation()
        const isBatch = order.batch ? '-batch' : ''
        const idOrder = order.batch ? order.batch : order.id
        setGoto(`/orders/${idOrder}/shopping${isBatch}`)
    }

    const onPickUp = (e) => {
        e.stopPropagation()
        const action = () => updateOrderStatus(order.id, 5)
        showModal({ action })
    }

    const onComplete = (e) => {
        e.stopPropagation()
        const action = () => updateOrderStatus(order.id, 6)
        showModal({ action })
    }

    const cardOnClick = () => {
        setGoto(`/orders/${order.id}`)
    }

    // texts
    let texts = {
        "orders.instructions": "Instructions",
        "orders.batch": "Batch",
        "orders.items": "Items",
        "orders.start_shopping": "Start Shopping",
        "orders.pick_up": "Pick up order",
        "orders.complete": "Complete Delivery'",
        "orders.congratulations": "Congratulations!",
        "orders.congrats_subtitle": "You have completed the delivery successfully.",
    }

    Object.keys(texts).map((x) => texts[x] = <FormattedMessage id={x} defaultMessage={texts[x]}/>)
    // end texts

    const normalCard =  (
        <Card className="mb-3" onClick={cardOnClick}>
            <Body>
                <Photo $src={order.photo} />
                <Title $state={AppState.state}> {order.name} </Title>

                <Label> {order.address} </Label>
                <p className="mb-0"> {order.building_number} </p>
                
                <Label> {texts["orders.instructions"]} </Label>
                <p className="mb-0"> {order.instruction} </p>
            </Body>
            <Footer>
                <FooterContainer>
                    { order.batch && <CardBadge $state={AppState.state} className='mr-2 mt-2' pill theme="light"> {texts["orders.batch"]} #{order.batch}</CardBadge> }
                    <CardBadge $state={AppState.state} className='mr-2 mt-2' pill theme="light"> {texts["orders.items"]} ({order.count})</CardBadge>
                </FooterContainer>

                {/* // buttons */}

                { (order.status <= 4 && !todo.shopped ) &&
                    <ActionButton $state={AppState.state} icon='local_mall' text={texts["orders.start_shopping"]} onClick={onStartShopping} /> }

                { (order.status <= 4 && todo.shopped ) &&
                    <ActionButton $state={AppState.state} icon='local_shipping' text={texts["orders.pick_up"]} onClick={onPickUp} /> }

                { order.status === 5 &&
                    <ActionButton $state={AppState.state} icon='assignment_turned_in' text={texts["orders.complete"]} onClick={onComplete} /> }

            </Footer>
        </Card>
    )


    const completedCard =  (
        <Card className="mb-3" onClick={cardOnClick}>
            <Body>
                <Photo $src={order.photo} />
                <Title $state={AppState.state}> {order.name} </Title>
               
                <Label $success $state={AppState.state} className='mb-0 mt-3 text-center'> {texts["orders.congratulations"]} </Label>
                <Text className='mb-4 text-center'>
                    {texts["orders.congrats_subtitle"]}
                    <span role="img" aria-label="smile">😃</span>
                </Text>
                
            </Body>
        </Card>
    )

    if (order.status === 6) return completedCard
    else return normalCard
}

const Body = styled(CardBody)`
    padding: 14px !important;
    padding-bottom: 0px !important;
`

const Photo = styled.div`
    width: 28px;
    height: 28px;
    position: absolute;
    margin-right: 14px;
    border-radius: 100%;
    overflow: hidden;
    right: 0;
    background-image: url(${({$src}) => $src});
    background-size: cover;
    background-position: center;
`

const Title = styled(CardTitle)`
    font-size: 18px !important;
    margin-bottom: 2px !important;
    font-weight: 800 !important;
    color: ${({$state}) => $state.colors.dark} !important;
`

const Footer = styled(CardFooter)`
    padding: 14px !important;
    min-height: 54px;
    display: flex;
    align-items: flex-end;
    padding-top: 8px !important;
`

const FooterContainer = styled.div`
    flex: 1;
`

const Label = styled.p`
    margin-bottom: -7px;
    font-weight: 600 !important;

    ${(props) => 
        props.$success && 
            `color: ${props.$state.colors.primary};
            font-weight: 700 !important;
            font-size: 17px;`
    }
`

const Text = styled.p`
    line-height: 1.16rem;
`

const CardBadge = styled(Badge)`
    background-color: ${({$state}) => $state.colors.light_2} !important;
`

const CardButton = styled(Button)`
    right: 0;
    padding: 9px !important;
    border-radius: 8px !important;
    background-color: ${({$state}) => $state.colors.primary} !important;
    border-color: ${({$state}) => $state.colors.primary} !important;
`

const Icon = styled.i`
    font-weight: 900;
    margin-right: 4px;
    color: ${({$state}) => $state.colors.white} !important;
`

const ActionButton = (props) => (
    <CardButton {...props} theme="success" onClick={props.onClick}>
        <Icon {...props} className='material-icons'>{props.icon}</Icon> {props.text}
    </CardButton>
)


OrderCard.propTypes = {
    item: PropTypes.object
  }
  
OrderCard.defaultProps = {
    item: {
        name: 'Deliver user1’s order ',
        address: '44 Gertrude Avenue #apt #307',
        photo: 'https://storage.googleapis.com/ur-files/webimages/assets/profile-placeholder.png'
    }
  }

export default OrderCard
