import { maps } from '@Config/config'

class Order {
    id = ''
    status = ''
    batch = ''
    count = 0
    sub_total = ''
    products = []
    instruction = ''
    date = ''
    todo = {assigned: true, checkout: false, shopped: false, picked_up: false, delivered: false, rated: false}
    checkout = {bags: '', keep_bill: ''}
    store = {name: '', address: ''}
    user = { id: '', first_name: '', last_name: '', photo: '', phone: '', phone_code: ''  }
    address = { id: '', service_address: '', building_number: '', land_mark: '', latitude: '', longitude: '', map: `${maps.endpoint}?markers=icon:${maps.marker}|{{latitude}},{{longitude}}&key=${maps.api_key}&zoom=18&size=600x400&maptype=roadmap&scale=2` }

    constructor (payload) { 
        this.id = payload?.id || ''
        this.status = payload?.status_code || 0
        this.batch = payload?.batch_id || 0
        this.sub_total = payload?.sub_total || ''
        this.date = payload?.date || ''
        this.instruction = payload?.instruction || ''
        this.store = {
            name: payload?.store || '',
            address: payload?.address_string || ''
        }
        this.user = {
            id: payload?.user_id || '',
            first_name: payload?.user_name || '',
            last_name: payload?.user_last_name || '',
            phone: payload?.user_phone || '',
            phone_code: payload?.user_phone_code|| '',
            photo: payload?.photo || 'https://storage.googleapis.com/ur-files/webimages/assets/profile-placeholder.png'
        }
        this.address = {
            id: payload?.user_address_id || ''
        }

        return this
    }

    fromJSON (payload) {
        Object.assign(this, payload)
        return this
    }

    setAddress (payload) { 
        const address = {
            service_address: payload?.service_address || '',
            building_number: payload?.building_number || '',
            land_mark: payload?.land_mark || '',
            latitude: payload?.latitude || '',
            longitude: payload?.longitude || '',
            map: `${maps.endpoint}?markers=icon:${maps.marker}|${payload?.latitude},${payload?.longitude}&key=${maps.api_key}&zoom=18&size=600x400&maptype=roadmap&scale=2`
        }
        this.address = {...this.address, ...address}
        return this.address
    }

    setProducts (payload) {
        this.products = payload || []
        return this.products

    }

    cardView () { 
        return {
            id: this.id,
            status: this.status,
            name: `${this.user.first_name} ${this.user.last_name}`,
            address: this.address.service_address,
            building_number: this.address.building_number,
            land_mark: this.address.land_mark,
            photo: this.user.photo,
            batch: this.batch,
            count: this.products.length,
            instruction: this.instruction,
            store: this.store.name,
            store_address: this.store.address,
            phone: `+${this.user.phone_code} ${this.user.phone}`,
            latitude: this.address.latitude,
            longitude: this.address.longitude,
            map: this.address.map,
            checkout: this.checkout
        }
    }
}

export default Order
