import React from 'react'
import { AppProvider } from './app'
import { OrderesProvider } from './orders'
import { LanguageProvider } from './language'

const GlobalProvider = ({ children }) => {
  return (
      <AppProvider>
        <LanguageProvider>
          <OrderesProvider>
              { children }
          </OrderesProvider>
        </LanguageProvider>
      </AppProvider>
  )
}

export default GlobalProvider