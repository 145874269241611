import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react"
import Loading from '@Components/layout/loading'
import MainShopping from "@Components/shoppingBatch/main";
import { useParams } from "react-router-dom";
import useOrders from "@Hooks/useOrders";
import useApp from "@Hooks/useApp";
import { FormattedMessage } from "react-intl";

const view = {
  title: <FormattedMessage id="view.title.shopping" defaultMessage="Shopping"/>,
  shadow: false,
  left_visible: true
}

const View = () => {
  const { id } = useParams()
  const { orders, getOrders } = useOrders()
  const { setNavbar } = useApp()
  const [isLoading, setloading] = useState(!orders.length)

  useEffect(() => {
    setNavbar(view)

    const fetchOrders = async () => {
      if (!orders.length && isLoading) {
        await getOrders()
        setloading(false)  
      }
    }

    fetchOrders()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isLoading])


  return (
    <Container fluid className="main-content-container px-3 pt-3">
      <Row className="mb-0">
        <Col lg="8">
          { isLoading ? <Loading /> : <MainShopping batch_id={id} /> }
        </Col>
      </Row>
    </Container>
  )
}

export default View
