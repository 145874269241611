import React, { createContext, useReducer } from 'react'
import reducer from './orders.reducer'

const initialState = {
  orders: [],
}

const store = createContext(initialState)

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <store.Provider value={{ state, dispatch }}>
      {children}
    </store.Provider>
  )
}

export { store, Provider }