import types from './app.types'

const reducer = (state, action) => {
        
    switch(action.type) {
      //
      case types.SET_NAVBAR:
        return { ...state, navbar: {...state.navbar, ...action.payload} }
      // 
      //
      case types.SHOW_ALERT:
        return { ...state, alert: {...state.alert, ...action.payload} }
      // 
      //
      case types.HIDE_ALERT:
        return { ...state, alert: {...state.alert, visible: false} }
      // 
      //
      case types.REDIRECT:
        return { ...state, redirect: action.payload}
      // 
      //
      case types.GOTO:
        return { ...state, goto: action.payload }
      // 
      //
      case types.SHOW_MODAL:
        return { ...state, modal: {...action.payload, visible: true} }
      // 
      //
      case types.HIDE_MODAL:
        return { ...state, modal: {...state.modal, visible: false} }
      // 
      //
      case types.SET_OFFLINE:
        return { ...state, offline: action.payload }
      // 
      //
      case types.SET_LANG:
        return { ...state, lang: action.payload }
      // 
      //
      case types.SET_COMPATIBLE:
        return { ...state, compatible: action.payload }
      // 
      //
      case types.SET_SESSION:
        return { ...state, session: action.payload }
      // 
      //
      case types.SET_ERROR:
        return { ...state, hasError: action.payload }
      //
      default:
        return state
    }
  }

  export default reducer