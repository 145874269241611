import React, { useState, useEffect, useContext } from 'react'
import { CardTitle, ButtonGroup } from 'shards-react'
import PropTypes from 'prop-types'
import ButtonItem from './button'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'
import useOrders from '@Hooks/useOrders'
import useApp from '@Hooks/useApp'
import { FormattedMessage } from 'react-intl'

const MainDetail = ({id}) => {
    const appState = useContext(AppStore)
    const { getOrderById, updateOrderStatus } = useOrders()
    const { setGoto, showModal } = useApp()
    const item = getOrderById(id)?.cardView()
    const todo = getOrderById(id)?.todo

    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        console.log('ORDER_ID', item, id)
        if (!item) return setGoto('/orders')

        setLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, item, setLoading])

    const callOnClick = () => {
        const a = document.createElement('a')
        a.href=`tel:${item.phone}`
        a.click()
    }
    const smsOnClick = () => {
        const a = document.createElement('a')
        a.href=`sms:${item.phone}`
        a.click()
    }
        
    const navigateOnClick = () => {
        const a = document.createElement('a')
        a.href=`geo:${item.latitude},${item.longitude}?=20&q=${item.latitude},${item.longitude}(${item.name})`
        a.click()
    }

    const shopOnClick = (e) => {
        e.stopPropagation()
        const isBatch = item.batch ? '-batch' : ''
        const idOrder = item.batch ? item.batch : id
        setGoto(`/orders/${idOrder}/shopping${isBatch}`)
    }
    
    const pickUpOnClick = (e) => {
        e.stopPropagation()
        const action = () => updateOrderStatus(id, 5)
        showModal({ action })
    }

    const completeOnClick = (e) => {
        e.stopPropagation()
        const action = () => updateOrderStatus(id, 6)
        showModal({ action })
    }

    // texts
    let texts = {
        "orders.start_shopping": "Start Shopping",
        "orders.pick_up": "Pick up order",
        "orders.complete": "Complete Delivery'",
        "order_detail.navigate": "Navigate",
        "order_detail.message": "Message",
        "order_detail.call": "Call",
        "order_detail.client_address": "Client Address",
        "order_detail.store_name": "Store name",
        "order_detail.store_address": "Store name"
    }

    Object.keys(texts).map((x) => texts[x] = <FormattedMessage id={x} defaultMessage={texts[x]}/>)
    // end texts

    if (isLoading) return ''

    return (
        <>
            <Title>{item.name}</Title>
            <Image $src={item.map}/>
            <ButtonContainer $state={appState.state} className='my-3 w-100'>
                
                { (item.status <= 4 && !todo.shopped ) && <ButtonItem text={texts["orders.start_shopping"]} ico="local_mall" cb={shopOnClick}/> }
                
                { (item.status <= 4 && todo.shopped ) && <ButtonItem text={texts["orders.pick_up"]} ico="local_shipping" cb={pickUpOnClick}/> }
                
                { item.status === 5 && <ButtonItem text={texts["orders.complete"]} ico="assignment_turned_in" cb={completeOnClick}/> }
                
                <ButtonItem text={texts["order_detail.navigate"]} ico="near_me" cb={navigateOnClick}/>
                <ButtonItem text={texts["order_detail.message"]} ico="local_post_office" cb={smsOnClick}/>
                <ButtonItem text={texts["order_detail.call"]} ico="phone" cb={callOnClick}/>
            </ButtonContainer>
            <p className="mb-0"> <b> {texts["order_detail.client_address"]} </b> </p>
            <p className="mb-0"> {item.address} </p>
            <p className="mb-0"> {item.building_address} </p>
            <p className="mb-2"> {item.land_mark} </p>

            <p className="mb-0"> <b> {texts["order_detail.store_name"]} </b> </p>
            <p className="mb-2"> {item.store} </p>

            <p className="mb-0"> <b> {texts["order_detail.store_address"]} </b> </p>
            <p className="mb-0"> {item.store_address} </p>
        </>
    )
}

const Title = styled(CardTitle)`
    font-size: 20px !important;
    margin-bottom: 20px !important;
    font-weight: 700 !important;
`

const Image = styled.div`
    width: 100%;
    min-height: 200px;
    border-radius: 5px;
    background-image: url(${({$src}) => $src});
    background-size: cover;
    background-repeat: no-repeat;
`

const ButtonContainer = styled(ButtonGroup)`
    align-items: flex-start;
    display: flex !important;
    justify-content: flex-end !important;
    height: 115px;
    background-color: ${({$state}) => $state.colors.light_3};
`

MainDetail.propTypes = {
    id: PropTypes.string
  }
  
  MainDetail.defaultProps = {
      id: 0
  }

export default MainDetail
