import React, { createContext, useReducer } from 'react'
import reducer from './app.reducer'
import { colors, theme } from '@Config/theme'
import BottomItems from '@Config/navItems'
import getAlert from '@Config/alert'
import { defaultLang } from '@Config/config'
import { getSession } from '@Utils/auth'

const lang = localStorage.getItem('lang') || defaultLang

const initialState = {
  lang: lang,

  loaded: false,
  hasError: false,

  modules_loaded: {
    orders: false,
    items: false,
    todo: false
  },

  navbar: {
    shadow: true,
    left_visible: false,
    title: 'Work App',
  },

  bottom_navigation: {
    items: BottomItems,
  },

  modal: {
    action: () => null,
    visible: false,
    text: 'Are you sure you want to do this?'
  },

  redirect: null,
  goto: null,

  offline: false,
  compatitle: true,

  todo: {}, // local
  orders: [], // from API
  products: {}, // from API
  to_shopping: {}, // local

  alert: getAlert('DEFAULT'),
  session: getSession(),
  version: '0.0.3',

  colors,
  theme
}

const store = createContext(initialState)

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <store.Provider value={{ state, dispatch }}>
      {children}
    </store.Provider>
  )
}

export { store, Provider }