import React, { useContext } from "react";
import PropTypes from 'prop-types'
import { CardTitle, CardSubtitle } from "shards-react"
import emptyImage from '@Assets/images/layout/empty.png'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'

const imgEmpty = new Image()
imgEmpty.src = emptyImage

const Empty = ({title, subtitle, style}) => {
  const Appstate = useContext(AppStore)
  return (
    <EmptyContainer style={style}>
      <Card>
        <Photo $src={emptyImage} />
        <Title $state={Appstate.state}> {title} </Title>
        <Subtitle $state={Appstate.state}> {subtitle} </Subtitle>
      </Card>
    </EmptyContainer>
  )
}

const EmptyContainer = styled.div`
  height: calc(100vh - 170px);
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
`

const Card = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Title = styled(CardTitle)`
  font-size: 22px;
  font-weight: normal !important;
  margin-bottom: 16px !important;
  color: ${({$state}) => $state.colors.gray_2} !important;
`

const Subtitle = styled(CardSubtitle)`
  font-size: 14px;
  color: ${({$state}) => $state.colors.gray_2} !important;
`

const Photo = styled.div`
  width: 150px !important;
  height: 150px !important;
  background-image: url(${({$src}) => $src});
  background-size: contain;
`

Empty.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

Empty.defaultProps = {
  title: 'No delivery checkout',
  subtitle: 'You don’t have any delivery checkout'
}

export default Empty
