import React , { useContext } from 'react'
import { Button } from 'shards-react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'

const LeftButton = () => {
  const appState = useContext(AppStore)
  const history = useHistory()
  const onClick = () => history.goBack()
  return (
    <ButtonLeft onClick={onClick} className='nav-link nav-link-icon d-sm-inline d-md-inline text-center'>
      <Icon $state={appState.state} className='material-icons'>arrow_back_ios</Icon>
    </ButtonLeft>
  )
}

const ButtonLeft = styled(Button)`
  min-width: auto !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
`

const Icon = styled.i`
  padding-left: 10px;
  color: ${({$state}) => $state.colors.dark};
  position: inherit !important;
  font-weight: 600;
`

export default LeftButton
