// import { Store } from "../../store"

import { writeLocalStore, readLocalStore } from "../auth"

function cleanDuplicates (array) {
    return array.filter((item, i) => array.indexOf(item) === i)
}

function cleanObjectDuplicates (array) {
    return array.filter((item, i, arr) => arr.findIndex((x) => (x.id === item.id)) === i )
}

function mergeItem (array, payload) {
    const index = array.findIndex((x) => x.id === payload.id)
    array[index] = Object.assign(array[index], payload)
}

function addItemsFilter (items) {
    return items.map((item) => {
        return {
            id: item.id,
            is_found: false,
            is_checked: false
        }

    })
}

// localstorage

function getShopping () {
    try {
        const data = JSON.parse(readLocalStore('items')) || {}
        return data
    } catch (err) {
        return {}
    }
}

function getFilteredShopping (items) {        
    try {
        const data = JSON.parse(readLocalStore('items')) || {}
        const itemsIds = items.map((x) => x.id)

        const result = Object.keys(data).reduce((prev, current) => {
            current = parseInt(current)
            if (itemsIds.includes(current)) prev[current] = data[current]
            return prev
        }, {})

        return result
    } catch (err) {
        return {}
    }
}

function setShopping (payload) {
    const data = JSON.stringify(payload)
    writeLocalStore('items', data)
}

function getShoppingById (id) {
    try {
        const data = JSON.parse(readLocalStore('items')) || {}
        return data[id] || {}
    } catch (err) {
        return {}
    }
}

function setShoppingById (id, payload) {
    const current = getShopping()
    current[id] = payload
    const data = JSON.stringify(current)
    writeLocalStore('items', data)
}

// localstorage ToDo

function getToDo () {
    try {
        const data = JSON.parse(readLocalStore('todo')) || {}
        return data
    } catch (err) {
        return {}
    }
}

function getFilteredToDo (items) {
    try {
        const data = JSON.parse(readLocalStore('todo')) || {}
        const itemsIds = items.map((x) => x.id)

        const result = Object.keys(data).reduce((prev, current) => {
            current = parseInt(current)
            if (itemsIds.includes(current)) prev[current] = data[current]
            return prev
        }, {})

        return result
    } catch (err) {
        return {}
    }
}

function setToDo (payload) {
    const data = JSON.stringify(payload)
    writeLocalStore('todo', data)
}

function getToDoById (id) {
    try {
        const data = JSON.parse(readLocalStore('todo')) || {}
        return data[id] || {}
    } catch (err) {
        return {}
    }
}

function setToDoById (id, payload) {
    const current = getToDo()
    current[id] = payload
    const data = JSON.stringify(current)
    writeLocalStore('todo', data)
}


export {
    cleanDuplicates,
    cleanObjectDuplicates,
    mergeItem,
    addItemsFilter,
    getShopping,
    getFilteredShopping,
    setShopping,
    getShoppingById,
    setShoppingById,
    getToDo,
    setToDo,
    getToDoById,
    setToDoById,
    getFilteredToDo
}
