import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react"
import MainProfile from "@Components/profile/main";
import Loading from "@Components/layout/loading";
import useApp from "@Hooks/useApp";
import { FormattedMessage } from "react-intl";
import useAuth from "@Hooks/useAuth";

const view = {
  title: <FormattedMessage id="view.title.profile" defaultMessage="Profile"/>,
  shadow: true,
  left_visible: false
}

const View = () => {
  const { setNavbar, } = useApp()
  const { session } = useAuth()
  const [isLoading, setloading] = useState(!session)

  
  useEffect(() => {
    setNavbar(view)
    setloading(false)
  }, [setloading, setNavbar])
  
  return (
    <Container fluid className="main-content-container px-3 pt-3">
      <Row className="mb-4">
        <Col lg="8">
        { isLoading ? <Loading /> :  <MainProfile /> }
        </Col>
      </Row>
    </Container>
  )
}

export default View
