import React from 'react'
import ReceiptCard from './card'
import useCheckout from '@Hooks/useCheckout'

const MainReceiptCheckout = () => {
    const { orders } = useCheckout()
    const cards = orders.map((order, i) => <ReceiptCard item={order} key={i}/>)

    return cards
}


export default MainReceiptCheckout
