import axios from 'axios'
// import { getToken } from '../auth'

const axiosRequest = async (payload, inerceptor) => {
    const { url, method, data, params, headers } = payload || {}

    const axiosPayload = {
        url,
        method,
        headers,
        params,
        data
    }

    axios.interceptors.response.use(null, inerceptor)

    const response = await axios(axiosPayload)
    
    return response
}


const request = () => {}
export { axiosRequest, request }
