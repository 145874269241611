import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, CardFooter, Button, Badge } from 'shards-react'
import PropTypes from 'prop-types'
import Modal from './modal'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'
import useShopping from '@Hooks/useShopping'
import { FormattedMessage } from 'react-intl'

const ProductCard = ({item}) => {
    console.log(item)
    const appState = useContext(AppStore)
    const [visible, setVisible] = useState(false)
    const [isFooter, setFooter] = useState(false)
    const { updateStatus } = useShopping()

    useEffect(() => {
        setFooter(item.status)

    }, [item.status])

    const imageOnlick = () => {
        setVisible(true)
    }

    const onCloseModal = () => {
        setVisible(false)
    }

    const onNotFound = () => {
        updateStatus(item.order_id, item.id, 'not_found')
    }

    const onFound = () => {
        updateStatus(item.order_id, item.id, 'found')
    }

    const onRemove = () => {
        updateStatus(item.order_id, item.id, '')
    }

    // texts
    let texts = {
        "shopping.found": "Found",
        "shopping.not_found": "NotFound",
        "shopping.remove": "Remove",
        "shopping.category": "Category",
        "shopping.price": "Price",
        "shopping.quantity": "Quantity"
    }

    Object.keys(texts).map((x) => texts[x] = <FormattedMessage id={x} defaultMessage={texts[x]}/>)
    // end texts

    const footer2 = (
        <Footer $checked>
            <FooterPillContainer>
                { item.status === 'found' && 
                    <Pill $state={appState.state} pill theme="success">
                        <Icon $state={appState.state} className='material-icons'>check</Icon> {texts["shopping.found"]}
                    </Pill>
                }
                { item.status === 'not_found' && 
                    <Pill $state={appState.state} pill theme="danger">
                        <Icon $state={appState.state} className='material-icons'>close</Icon> {texts["shopping.not_found"]}
                    </Pill>
                }
            </FooterPillContainer>
            <ButtonAction $state={appState.state} $color='light' theme="ligth" onClick={onRemove} >
                <Icon $state={appState.state} $dark className='material-icons'>delete</Icon> {texts["shopping.remove"]}
            </ButtonAction>
        </Footer>
    )

    const footer = (
        <Footer>
            <ButtonAction $state={appState.state} $color='light' theme="ligth" onClick={onNotFound} >
                <Icon $state={appState.state} $dark className='material-icons'>close</Icon> {texts["shopping.not_found"]}
            </ButtonAction>
            <ButtonAction $state={appState.state} $color='primary' theme="success" onClick={onFound}>
                <Icon $state={appState.state} className='material-icons'>check</Icon> {texts["shopping.found"]}
            </ButtonAction>
        </Footer>
    )

    return (
        <>
            <Modal image={item.image} visible={visible} onClose={onCloseModal} />
            <Card className="mb-3">
                <Body>
                    <Content>
                        <Title $truncate2 className='mb-1'> {item.name} </Title>

                        <Text $truncate> <b> {texts["shopping.category"]}:</b> {item.category.name} </Text>
                        <TextGroup>
                            <Text> <b> {texts["shopping.price"]}:</b> ${(item.price.toFixed(2))} </Text>
                            <Text className='ml-4'> <b> {texts["shopping.quantity"]}:</b> {item.quantity}</Text>

                        </TextGroup>

                    </Content>
                    <Photo $src={item.image} onClick={imageOnlick} />
                </Body>
                { isFooter ? footer2 : footer}
            </Card>
        </>
    )
}

const Footer = styled(CardFooter)`
    padding: 14px !important;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 0px !important;    

    ${({$checked}) => !$checked && `min-height: 54px;`}
`

const FooterPillContainer = styled.div`
    flex: 1;
`

const TextGroup = styled.div`
    display: flex;
    padding-top: 4px;
`

const Pill = styled(Badge)`
    ${(props) => 
        props.theme === 'success' && `background-color: ${props.$state.colors.primary} !important;`
    }
`

const Icon = styled.i`
    font-weight: 900;
    margin-right: 4px;
    color: ${({$state}) => $state.colors.white};

    ${(props) => props.$dark && `color: ${props.$state.colors.dark_2} !important;`}
`

const ButtonAction = styled(Button)`
    margin-left: 8px;
    padding: 9px !important;
    border-radius: 8px !important;

    ${(props) => props.$color === 'light' && `background-color: ${props.$state.colors.light_2} !important;`}
    ${(props) => props.$color === 'primary' && `background-color: ${props.$state.colors.primary} !important; border-color: ${props.$state.colors.primary} !important;`}
`

const Body = styled(CardBody)`
    padding: 14px !important;
    padding-bottom: 8px !important;
    display: flex;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 4px;
    flex: 1;
    min-width: 0;
`

const Title = styled(CardTitle)`
    font-size: 18px !important;
    margin-bottom: 14px !important;
    font-weight: 700 !important;

    ${({$truncate2}) =>
        $truncate2 && `
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        
        `
    }
`

const Text = styled(CardTitle)`
    margin-top: -4px;
    margin-bottom: 0 !important;

    ${({$truncate}) => 
        $truncate && `
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;`
    }
`

const Photo = styled.div`
    width: 60px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;

    background-image: url(${({$src}) => $src});
    background-size: cover;
    background-position: center;
`

ProductCard.propTypes = {
    item: PropTypes.object
  }
  
  ProductCard.defaultProps = {
    item: {
        id: 1,
        price: '0.00',
        quantity: 1,
        store_name: 'walmart',
        product: {
            name: 'Product Name',
            image_url: 'https://storage.googleapis.com/ur-files/webimages/upload/MenuItem/1592866532_76335.jpg'
        }
    }
  }

export default ProductCard
