import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import '@Assets/styles/components/core.module.css'
import Modal from './modal'
import useApp from '@Hooks/useApp'
import useAuth from '@Hooks/useAuth'


const UICore = ({auth, children}) => {
    const { setOffline, redirect, setRedirect, goto, setGoto, setCompatible } = useApp()
    const { loadSession } = useAuth()
    const history = useHistory()
    const [isLoading, setLoading] = useState(true)
    // const [isNotCompatible, setNotCompatible] = useState(window.innerWidth > 767)
    
    useEffect(() => {
      // windows size
      window.addEventListener('resize', () => {
        setCompatible( window.innerWidth < 767 )
      })

      // redirect
      if (redirect) {
        history.replace(redirect)
        setRedirect(null)
      }

      // goto
      if (goto) {
        history.push(goto)
        setGoto(null)
      }

      // online / offline
      window.addEventListener("online", () => setOffline(false))
      window.addEventListener("offline", () => setOffline(true))

      loadSession()
      setLoading(false)

    }, [isLoading, auth, history, redirect, setRedirect, setOffline, goto, setGoto, loadSession, setCompatible])

    if (isLoading) return ( <></> )

    return ( <> <Modal /> { children } </> )
}

UICore.propTypes = {
    auth: PropTypes.bool
  }
  
  UICore.defaultProps = {
    auth: false
  }
  

export default UICore