import jwtDecode from 'jwt-decode'

// internal functions
function decode (token) {
    return jwtDecode(token)
}

function writeLocalStore (key, data) {
    localStorage.setItem(key, data)
}

function readLocalStore (key) {
    return localStorage.getItem(key)
}

function deleteLocalStore (key) {
    localStorage.removeItem(key)
}

function clearLocalStore () {
    localStorage.clear()
}

// 

function getToken () {
    return readLocalStore('token')
}

function setSession (token) {
    writeLocalStore('token', token)
}

function getSession () {
    const token = readLocalStore('token')
    if (!token) return null
    return decode(token)
}

function deleteSession () {
    return deleteLocalStore('token')
}

// versioning
function setVersion (version) {
    writeLocalStore('_v', version)
}

function getVersion () {
    return readLocalStore('_v') || null
}

export {
    decode,
    writeLocalStore,
    readLocalStore,
    deleteLocalStore,
    clearLocalStore,
    setSession,
    getSession,
    deleteSession,
    getToken,
    setVersion,
    getVersion
}
