import React, { useContext, useEffect } from "react";
import PropTypes from 'prop-types'
import { CardTitle, CardSubtitle } from "shards-react"
import noPcImage from '@Assets/images/layout/no-pc.png'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'
import useApp from "@Hooks/useApp";
import { FormattedMessage } from "react-intl";

const NotAllowed = ({title, subtitle}) => {
  const appState = useContext(AppStore)
  const { setCompatible } = useApp()

  useEffect(() => {
    window.addEventListener('resize', () => {
      setCompatible( window.innerWidth < 767 )
    })

  }, [setCompatible])

  return (
    <Container>
      <Card>
        <Image $src={noPcImage}/>
        <Title $state={appState.state}> {title} </Title>
        <Subtitle $state={appState.state}> {subtitle} </Subtitle>
      </Card>
    </Container>
  )
}

const Container = styled.div`
  height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
`

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Image = styled.div`
  width: 200px !important;
  height: 200px !important;
  background-image: url(${({$src}) => $src});
  background-size: contain;
`

const Title = styled(CardTitle)`
  font-size: 22px;
  font-weight: normal !important;
  margin-bottom: 16px !important;
  color: ${({$state}) => $state.colors.gray_2} !important;
`

const Subtitle = styled(CardSubtitle)`
  font-size: 14px;
  color: ${({$state}) => $state.colors.gray_2} !important;
`

NotAllowed.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

NotAllowed.defaultProps = {
  title: <FormattedMessage id="app.not_allowed.title" defaultMessage="No Allowed on Desktop"/>,
  subtitle: <FormattedMessage id="app.not_allowed.sub_title" defaultMessage="You have to browser from your Phone"/>
}

export default NotAllowed
