module.exports = {
    theme: 'light',
    colors: {
        primary: 'rgb(0, 195, 237)',
        secundary: '',

        dark: 'rgb(13, 17, 54)',
        dark_2: 'rgb(11, 39, 73)',

        gray: '#5A6169',
        gray_2: '#828282',

        light: '#E2E5E9',
        light_2: '#F2F6FA',
        light_3: '#FBFBFB',
        
        white: '#FFFFFF',
    }
    
}
  