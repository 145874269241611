import React, { useContext } from 'react'
import { Card, CardBody, Button } from 'shards-react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'
import { FormattedMessage } from 'react-intl'

const ItemReceipt = ({value, cb}) => {
    const appState = useContext(AppStore)

    // texts
    let texts = {
        "receipt_check.save": "Save",
        "receipt_check.keep_receipt": "Keep the reciept with you",
        "receipt_check.keep_receipt_message": "Please do not include it in the bag for the customer. Customers will receive an electronic receipt. You may need the receipt for returns."
    }

    Object.keys(texts).map((x) => texts[x] = <FormattedMessage id={x} defaultMessage={texts[x]}/>)
    // end texts

    const cardInput = (
        <Card className="mb-2">
            <Body>

                <Title className='mb-2'> {texts["receipt_check.keep_receipt"]} </Title>
                <Text className='mb-2'> {texts["receipt_check.keep_receipt_message"]} </Text>
                
                <ButtonSave $state={appState.state} onClick={cb} className='px-4' theme="success"> {texts["receipt_check.save"]} </ButtonSave>

            </Body>
        </Card>
    )


    const cardCompleted = (
        <Card className="mb-2">
            <Body $completed>

                <Title $completed className='mb-0'> {texts["receipt_check.keep_receipt"]} </Title>
                <IconContainer $state={appState.state} className='p-2'>
                    <Icon $state={appState.state} className='material-icons'> check </Icon>
                </IconContainer>
                
            </Body>
        </Card>
    )

    if (value) return cardCompleted
    else return cardInput

}

const Body = styled(CardBody)`
    padding: 14px !important;
    display: flex;

    ${({$completed}) => !$completed && `
        flex-direction: column;
        align-items: center;`
    }
`

const Title = styled.p`
    margin-top: -4px;
    
    ${({$completed}) => 
        $completed
        ? 
            `display: flex;
            flex: 1;`
        : 
            `width: 100%;
            font-weight: 700;`
    }
`

const Text = styled.p`
    margin-top: -4px;
    line-height: normal;
`

const ButtonSave = styled(Button)`
    background-color: ${({$state}) => $state.colors.primary} !important;
    border-color: ${({$state}) => $state.colors.primary} !important;
`

const IconContainer = styled.div`
    display: flex;
    width: 50px;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem !important;
    border-radius: 10p;
    margin: -14px -14px -14px 4px;
    border-top-right-radius: .625rem;
    border-bottom-right-radius: .625rem;

    background-color: ${({$state}) => $state.colors.primary} !important;
`

const Icon = styled.div`
    font-weight: 900;
    margin-right: 4px;
    font-size: 24px !important;
    color: ${({$state}) => $state.colors.white} !important;
`


ItemReceipt.propTypes = {
    item: PropTypes.object
  }
  
ItemReceipt.defaultProps = {
    item: {
        bags: 0,
        keep_bill: false
    }
  }

export default ItemReceipt
