import React from "react"
import { FormattedMessage } from "react-intl"
import { Container, Row, Col } from "shards-react"
import Offline from './Offline'

const ErrorView = ({children}) => {
  // texts
  let texts = {
    "app.error.title": "Oops!",
    "app.error.sub_title": "An error has occurred"
  }

  Object.keys(texts).map((x) => texts[x] = <FormattedMessage id={x} defaultMessage={texts[x]}/>)
  // end texts

  return (
      <Container fluid className="main-content-container px-3 pt-3">
        <Row className="mb-4">
          <Col lg="8">
              <Offline title={texts["app.error.title"]} subtitle={texts["app.error.sub_title"]} />
          </Col>
        </Row>
      </Container>
    )
}

export default ErrorView