import React, { useContext } from 'react'
import AwesomeModal from 'react-awesome-modal'
import { Button } from 'shards-react'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'
import useApp from '@Hooks/useApp'
import { FormattedMessage } from 'react-intl'


const Modal = () => {
    const { modal, hideModal } = useApp()
    const appState = useContext(AppStore)

    const onClick = () => {
        modal.action()
        closeModal()
    }

    const closeModal = () => hideModal()

    // texts
    let texts = {
        "app.modal.question": "Are you sure you want to do this?",
        "app.cancel": "Cancel",
        "app.continue": "Continue"
    }

    Object.keys(texts).map((x) => texts[x] = <FormattedMessage id={x} defaultMessage={texts[x]}/>)
    // end texts

    return (
        <AwesomeModal visible={modal.visible} width="300" effect="fadeInUp" onClickAway={closeModal}>
            <div className="p-3">
                <p className="mb-3"> {texts["app.modal.question"]} </p>
                <ButtonContainer>
                    <ButtonCancel $state={appState.state} theme="light" onClick={closeModal}> {texts["app.cancel"]} </ButtonCancel>
                    <ButtonAccept $state={appState.state} className="ml-3" onClick={onClick}> {texts["app.continue"]} </ButtonAccept>
                </ButtonContainer>
            </div>
        </AwesomeModal>
    )
}

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`

const ButtonCancel = styled(Button)`
    background-color: ${({$state}) => $state.colors.light_2} !important;
`

const ButtonAccept = styled(Button)`
    background-color: ${({$state}) => $state.colors.primary} !important;
    border: 1px solid  ${({$state}) => $state.colors.primary} !important;
`

export default Modal
