import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Container, Navbar } from 'shards-react'
import LeftButtom from './button'
import AlertComponent from '../Alert'
import styled from 'styled-components'
import useApp from '@Hooks/useApp'
import { AppStore } from '@Contexts/app'

const MainNavbar = ({ layout, stickyTop }) => {
  const appState = useContext(AppStore)
  const { navbar } = useApp()
  const {left_visible, title, shadow} = navbar

  return (
    <NavContainer $shadow={shadow} $stickyTop={stickyTop} className='main-navbar bg-white'>
      <Container className="p-0">
        <NavbarBody type="light" className='align-items-stretch flex-md-nowrap p-0'>

          {left_visible && <LeftButtom />}
          <Title $state={appState.state} $leftVisible={left_visible} className='text-center'> {title} </Title>

        </NavbarBody>
        <AlertComponent navbar />
      </Container>
    </NavContainer>
  )
}

const NavbarBody = styled(Navbar)`
  justify-content: flex-start !important;
`

const NavContainer = styled.div`
  ${({$shadow}) => 
    !$shadow && `
      box-shadow: none !important;`}

  ${({$stickyTop}) => 
    $stickyTop && `
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;`}
`

const Title = styled.h6`
  align-items: center;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.5rem;
  width: calc(100% - 7rem);
  justify-content: center;

  color: ${({$state}) => $state.colors.dark} !important;

  ${({$leftVisible}) => 
    !$leftVisible && `
      padding-left: 16px !important;
      margin-left: 3rem;`}
`

MainNavbar.propTypes = {
  layout: PropTypes.string,
  stickyTop: PropTypes.bool
}

MainNavbar.defaultProps = {
  stickyTop: true
}

export default MainNavbar
