import React, { useContext, useEffect, useState } from 'react'
import { Tabs, TabLink, TabContent } from 'react-tabs-redux'
import { Badge } from 'shards-react'
import ProductCard from './card'
import { Store } from '@Store'
import Empty from '../layout/Empty'
import Button from './button'

import styled from 'styled-components'
import { AppStore } from '@Contexts/app'

const MainShopping = ({id}) => {
    const appState = useContext(AppStore)
    const [cardsToPick, setCardsToPick] = useState([])
    const [cardsNotFound, setCardsNotFound] = useState([])
    const [cardsFound, setCardsFound] = useState([])

    useEffect(() => {

        const onChange = () => {
            const cardsLeft = cardsToPick()
            if (cardsLeft.length === 0) Store.setCheckout(id)

            setCardsToPick(cardsLeft)
            setCardsFound(cardsFound())
            setCardsNotFound(cardsNotFound())
        }
        
        const getItems = () => {
            const items = Store.getProduct(id)
            const toShoppingItems = Store.getToShopping(id).items
            return {items, toShoppingItems}
        }

        const cardsToPick = () => {
            const {items, toShoppingItems} = getItems()
            const itemsToPick = toShoppingItems.filter((item) => !item.is_checked)
            return itemsToPick.map((status, i) => {
                const [item] = items.filter((x) => x.id === status.id)
                return <ProductCard item={item} status={status} key={i} />
            })
        }
       
        const cardsNotFound = () => {
            const {items, toShoppingItems} = getItems()
            const itemsNotFound = toShoppingItems.filter((item) => item.is_checked && !item.is_found)
            return itemsNotFound.map((status, i) => {
                const [item] = items.filter((x) => x.id === status.id)
                return <ProductCard item={item} status={status} key={i} />
            })
        }
        const cardsFound = () => {
            const {items, toShoppingItems} = getItems()
            const itemsFound = toShoppingItems.filter((item) => item.is_checked && item.is_found)
            return itemsFound.map((status, i) => {
                const [item] = items.filter((x) => x.id === status.id)
                return <ProductCard item={item} status={status} key={i} />
            })
        }
        onChange()
        Store.toShoppingListener('add', onChange)
        return () => Store.toShoppingListener('remove', onChange)
        
    }, [id])

    const onClick = () => Store.goto('/receipt-check')


    return (
        <div>
            <Tabs>
                <TabsContainer>
                    <TabItem to="tab1">
                        <TabItemBody>
                            <Pill $state={appState.state} pill theme="light" className='mr-1' > { cardsToPick.length } </Pill> To-Pick
                            <div className="under_line" ></div>
                        </TabItemBody>
                    </TabItem>
                    <TabItem to="tab2">
                        <TabItemBody>
                            <Pill  $state={appState.state} pill theme="light" className='mr-1' > { cardsNotFound.length } </Pill> In Review
                            <div className="under_line" ></div>
                        </TabItemBody>
                    </TabItem>
                    <TabItem to="tab3">
                        <TabItemBody>
                            <Pill $state={appState.state} pill theme="light" className='mr-1'> { cardsFound.length } </Pill> Done
                            <div className="under_line" ></div>
                        </TabItemBody>
                    </TabItem>
                </TabsContainer>

                <TabItemContent for="tab1"> 
                    { cardsToPick.length ? cardsToPick : null}
                    { !cardsToPick.length ? <Button text="Receipt Check" ico="check" cb={onClick}/> : null }
                    { !cardsToPick.length && <Empty style={{height: 'calc(100vh - 170px - 130px)'}} title="No more products" subtitle="You no have more products to shop"/>}
                 </TabItemContent>
                <TabItemContent for="tab2">
                    { cardsNotFound.length ? cardsNotFound : null }
                    { !cardsToPick.length ? <Button text="Receipt Check" ico="check" cb={onClick}/> : null }
                    { !cardsNotFound.length && <Empty style={{height: 'calc(100vh - 170px - 130px)'}} title="No products reviewing" subtitle="The products to review will appears here"/>}
                </TabItemContent>
                <TabItemContent for="tab3">
                    { cardsFound.length ? cardsFound : null }
                    { !cardsToPick.length ? <Button text="Receipt Check" ico="check" cb={onClick}/> : null }
                    { !cardsFound.length && <Empty style={{height: 'calc(100vh - 170px - 130px)'}} title="No products done" subtitle="The products done will appears here"/>}
                </TabItemContent>
            </Tabs>
        </div>
    )
}

const TabsContainer = styled.div`
    display: flex;
    width: 100%;
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -16px;
    background-color: #FFF;
    position: fixed;
    z-index: 5;
    box-shadow: 0 0.125rem 0.625rem rgba(90,97,105,.12);
`

const TabItem = styled(TabLink)`
    display: flex;
    flex: 1;
    justify-content: center;
    background-color: #fff;
    border: none;
    padding: 8px 8px 0 8px;
    outline: none;
`

const TabItemBody = styled.div`
    width: 100%;
`

const TabItemContent = styled(TabContent)`
    padding: 16px 0px;
    padding-top: 60px !important;
    padding-bottom: 70px !important;
`

const Pill = styled(Badge)`
    background-color: ${({$state}) => $state.color.light_2} !important;
`

export default MainShopping
