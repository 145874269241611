import { FormattedMessage } from 'react-intl'

// texts
let texts = {
    "alerts.online": "Online",
    "alerts.offline": "offline",
    "alerts.invalid_credentials": "Email or Password incorrect, please try againg!",
    "alerts.login_success": "Welcome to Work app",
    "alerts.error": "Oops!, please try againg!"
}

Object.keys(texts).map((x) => texts[x] = <FormattedMessage id={x} defaultMessage={texts[x]}/>)
// end texts

const makeAlert = (alertName, payload) => {
  const alerts = {
    CUSTOM: payload,
    DEFAULT: {
      type: 'primary',
      title: texts["alerts.online"],
      visible: false
    },
    ONLINE: {
      type: 'success',
      title: texts["alerts.online"],
      visible: true
    },
    OFFLINE: {
      type: 'danger',
      title: texts["alerts.offline"],
      visible: true
    },
    INVALID_CREDENTIALS: {
      type: 'danger',
      title: texts["alerts.invalid_credentials"],
      visible: true
    },
    LOGIN_SUCCESS: {
      type: 'primary',
      title: texts["alerts.login_success"],
      visible: true
    },
    ERROR: {
      type: 'danger',
      title: texts["alerts.error"],
      visible: true
    },
  }
  return alerts[alertName]
}

export default makeAlert
  