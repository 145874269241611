import React, { useContext } from 'react'
import AwesomeModal from 'react-awesome-modal'
import { Button } from 'shards-react'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'

const Modal = ({image, visible, onClose}) => {
    const appState = useContext(AppStore)
    const defaultImage = 'https://us-central1-ur-tst-env.cloudfunctions.net/uride-image-resizer'

    return (
        <AwesomeModal styles={{overflow: "hidden"}} visible={visible} width="350" effect="fadeInDown" onClickAway={onClose}>
            <ButtonClose $state={appState.state} onClick={onClose} theme="light">
                <Icon className='material-icons'>close</Icon>
            </ButtonClose>
            <img style={{width: '100%'}} src={image} onError={(e)=>{ e.target.src=defaultImage}} alt="product" />
        </AwesomeModal>
    )
}

const ButtonClose = styled(Button)`
    position: absolute;
    right: 0px;
    border-radius: 100% !important;
    height: 40px;
    width: 40px;
    padding: 0 !important;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({$state}) => $state.colors.light_2} !important;
`

const Icon = styled.i`
    font-size: 20px !important;
    font-weight: 700 !important;
`

export default Modal
