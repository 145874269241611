import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react"
import { useParams } from "react-router-dom";
import MainDetail from '@Components/orderDetail/main'
import Loading from "@Components/layout/loading";
import useApp from "@Hooks/useApp";
import useOrders from "@Hooks/useOrders";
import { FormattedMessage } from "react-intl";

const view = {
  title:  <FormattedMessage id="view.title.orders_detail" defaultMessage="Navigate"/>,
  shadow: true,
  left_visible: true
}

const View = () => {
  const { setNavbar } = useApp()
  const { getOrders, orders } = useOrders()
  const { id } = useParams()
  const [isLoading, setloading] = useState(!orders.length)

  useEffect(() => {
    setNavbar(view)

    const fetchOrders = async () => {
      if (isLoading) await getOrders()
      setloading(false)
    }
    fetchOrders()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbar])

  return (
    <Container fluid className="main-content-container px-3 pt-3">
      <Row className="mb-4">
        <Col lg="8">
          { isLoading ? <Loading /> : <MainDetail id={id} /> }
        </Col>
      </Row>
    </Container>
  )
}

export default View
