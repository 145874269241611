import React from 'react'
import { CardTitle } from 'shards-react'
import PropTypes from 'prop-types'
import ItemBag from './itemBags'
import ItemReceipt from './itemReceipt'
import styled from 'styled-components'
import useCheckout from '@Hooks/useCheckout'

const ReceiptCard = ({item}) => {
    const { updateCheckout } = useCheckout()

    const onKeepBill= () => updateCheckout(item.id, 'keep_bill', true)
    const onBags = (value) => updateCheckout(item.id, 'bags', value)

    return (
        <div className="mb-4 pt-3">
            <Title className='mb-2'> {item.user.first_name} {item.user.last_name} </Title>
            <ItemBag cb={onBags} value={item.checkout.bags} />
            <ItemReceipt cb={onKeepBill} value={item.checkout.keep_bill} />
        </div>
    )
}

const Title = styled(CardTitle)`
    font-size: 18px !important;
    margin-bottom: 14px !important;
    font-weight: 700 !important;
`

ReceiptCard.propTypes = {
    item: PropTypes.object
  }
  
ReceiptCard.defaultProps = {
item: {
    bags: 0,
    keep_bill: true
}
}

export default ReceiptCard
