import React, { useContext } from 'react'
import { Card, CardBody } from 'shards-react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'

const Item = ({text, completed}) => {
    const appState = useContext(AppStore)

    return (
        <>
        <Card className="mb-2">
            <Body>

                <Text className='mb-0'> {text} </Text>
                <Button className='p-2' $completed={completed} $state={appState.state}>
                    <Icon $state={appState.state} className='material-icons'> check </Icon>
                </Button>
                
            </Body>
        </Card>
        </>
    )
}

const Body = styled(CardBody)`
    padding: 14px !important;
    display: flex;
`

const Text = styled.p`
    margin-top: -4px;
    display: flex;
    flex: 1;
`

const Button = styled.div`
    display: flex;
    width: 50px;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem !important;
    border-radius: 10p;
    margin: -14px -14px -14px 4px;
    border-top-right-radius: .625rem;
    border-bottom-right-radius: .625rem;

    background-color: ${(props) => 
        props.$completed 
            ? props.$state.colors.primary
            : props.$state.colors.light_2} !important;
`

const Icon = styled.i`
    color: ${({$state}) => $state.colors.white};
    font-weight: 900;
    margin-right: 4px;
`

Item.propTypes = {
    item: PropTypes.object
}
  
Item.defaultProps = {
    text: 'default text',
    completed: true
}

export default Item
