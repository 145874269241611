import useTodo from '@Hooks/useTodo'
import React from 'react'
import CheckList from './card'

const MainCheckList = () => {
    const { orders } = useTodo()

    const cards = orders.map((order, i) => <CheckList item={order} key={i}/>)

    return ( <>{ cards }</> )
}


export default MainCheckList
