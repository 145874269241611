import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import routes from './routes'

import 'bootstrap/dist/css/bootstrap.min.css'
import '@Assets/styles/shards-dashboards.1.1.0.min.css'
import '@Assets/styles/global.css'
import { loadVersion } from './utils/helper/version'

loadVersion()

const App = () => {
  const renderRoutes = routes.map((route, i) => {
    return (
      <Route
        key={i}
        path={route.path}
        exact={route.exact}
        component={(props => {
          return (
            <route.layout {...props}>
              <route.component {...props} />
            </route.layout>
          )
        })}
      />
    )
  })

  // set status bar theme white color
  document.getElementById('theme-android').content = 'white'
  document.getElementById('theme-ios').content = 'white'

  // disable all console.logs
  if (process.env.NODE_ENV !== "development") console.log = () => {}

  return (
    <Router>
      <Switch> { renderRoutes } </Switch>
    </Router>
  )
}


export default App
