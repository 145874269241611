import { useCallback, useContext, useMemo } from 'react'
import { OrdersStore, OrdersTypes } from '@Contexts/orders'
import useRequest from './useRequest'
import Order from 'models/v2/order.model'
import Product from 'models/v2/product.model'
import { mergeOrders } from '@Utils/helper/iterators'

const useOrders = () => {
    console.log('START USE_ORDERS')
    const ordersContext = useContext(OrdersStore)
    const ordersState = ordersContext.state
    const ordersDispatch = ordersContext.dispatch

    const { request, endpoints } = useRequest()

    // orders
    const orders = useMemo(() => ordersState.orders.map((x) => {
        const order = new Order()
        order.fromJSON(x)
        return order
    }), [ordersState])

    const loadOrders = (newOrders) => {
        let savedOrders = localStorage.getItem('shopping') || '{orders: []}'
        try {
            savedOrders = JSON.parse(savedOrders)
        } catch (e) {
            savedOrders = {orders: []}
        }
        const currentOrders = mergeOrders(newOrders, savedOrders.orders) //[...ordersHook.orders, savedOrders.orders]

        // save local
        console.log(newOrders)
        console.log(currentOrders)
        localStorage.setItem('shopping', JSON.stringify({orders: currentOrders}))
        return currentOrders
    }

    const getOrders = useCallback(async () => {
        console.log('START GET ORDERS')
        const requestPayload = {
            method: 'get',
            url: endpoints.orders.orders
        }
        const orders = await request(requestPayload)
        if (!orders) {
            ordersDispatch({type: OrdersTypes.SET_ORDERS, payload: []})
            return 'no orders'
        }
        console.log(orders)

        let newOrders = orders?.items?.map((x) => new Order(x))
        console.log(newOrders)

        for await (let x of newOrders) {
            // address
            const addressPayload = {
                method: 'get',
                url: endpoints.orders.address,
                params: { 'filter[id]': x?.address?.id }
            }
            const address = await request(addressPayload)
            if(address) {
                x.setAddress(address?.items[0] || {})
                console.log('ADDRESS', address)
            }

            // products
            const productsPayload = {
                method: 'get',
                url: `${endpoints.orders.products}/${x.id}/details`
            }
            let products = await request(productsPayload)
            console.log(products)
            if (products) {
                products = products?.map((y) => new Product(y))
                x.setProducts(products)
                console.log('PRODUCTS', products)
            }
        }

        newOrders = loadOrders(newOrders)

        ordersDispatch({type: OrdersTypes.SET_ORDERS, payload: newOrders})
        
    }, [endpoints, ordersDispatch, request])

    const getOrderById = useCallback((id) => {
        id = parseInt(id)
        const [order] = orders.filter((x) => x.id === id)
        return order
    }, [orders])

    const getOrderByBatchId = useCallback((id) => {
        id = parseInt(id)
        const [order] = orders.filter((x) => x.batch === id)
        return order
    }, [orders])

    const updateOrderStatus = useCallback((order_id, value) => {
        order_id = parseInt(order_id)
        console.log('UPDATE STATUS', value)
        
        const result = orders.reduce((prev, curr) => {
            if(curr.id === order_id) {
                curr.status = value
                // use todo
                // status: 5
                if (curr.status === 5) curr.todo.picked_up = true
                
                // status: 6
                if (curr.status === 6) curr.todo.delivered = true
                //
            }
            prev.push(curr)
            return prev
        }, [])

        // use request

        // save local
        localStorage.setItem('shopping', JSON.stringify({orders: result}))

        ordersDispatch({type: OrdersTypes.UPDATE_SHOPPING_ORDER, payload: result})
    }, [orders, ordersDispatch])

    return {
        orders,
        getOrders,
        getOrderById,
        getOrderByBatchId,
        updateOrderStatus
    }
}

export default useOrders