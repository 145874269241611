import React from 'react'
import { Container, Row, Col } from 'shards-react'
import LoginForm from '@Components/login/form'
import logo from '@Assets/images/logo.png'
import styled from 'styled-components'

const View = () => (
  <FluidContainer fluid className='main-content-container px-3'>
    <Row className='mb-4'>
      <Col lg="12">
       
       <PhotoContainer className=" mb-5">
          <Photo $src={logo}/>
       </PhotoContainer>
      
        <LoginForm />
        
      </Col>
    </Row>
  </FluidContainer>
)

const FluidContainer = styled(Container)`
  display: flex !important;
  padding-top: 100px !important;
  justify-content: center !important;
  min-height: 100vh !important;
  background-color: #fff;
`

const PhotoContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Photo = styled.div`
    width: 132px;
    height: 132px;
    background-image: url(${({$src}) => $src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`

export default View
