module.exports = {
  api: {
    // login: 'https://login.urideservices.com',
    passport: 'https://passport-api.tyvo.io',
    // shopping: 'https://shopping-api.urideservices.com'
    shopping: 'https://shopping-cart-api.tyvo.io'
  },
  maps: {
    api_key: 'AIzaSyDTfn8iyxHdbDJnpeboBJTuEF8d39gu7CM',
    endpoint: 'https://maps.googleapis.com/maps/api/staticmap',
    marker: 'https://i.pinimg.com/originals/53/ec/92/53ec929800d1282c9ef59cd27c8c45d6.png'
  },
  dafaulLang: 'es',
  version: '921D7'
}
