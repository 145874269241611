import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react"
import OrderCard from "@Components/home/card";
import Loading from '@Components/layout/loading'
import Empty from "@Components/layout/Empty";
import Offline from "@Components/layout/Offline";
import useApp from "@Hooks/useApp";
import useOrders from "@Hooks/useOrders";
import { FormattedMessage } from 'react-intl'

const view = {
  title: <FormattedMessage id="view.title.orders" defaultMessage="My Work"/>,
  shadow: true,
  left_visible: false
}

const View = () => {
  const { getOrders, orders } = useOrders()
  const { setNavbar, offline } = useApp()
  const [isLoading, setloading] = useState(!orders.length)

  useEffect(() => {
    setNavbar(view)

    const fetchOrders = async () => {
      await getOrders()
      setloading(false)
    }

    fetchOrders()
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbar])

  console.log(orders)
  // texts
  const emptyTitleText = <FormattedMessage id="empty.orders.title" defaultMessage="No orders assined"/>
  const emptySubTitleText = <FormattedMessage id="empty.orders.sub_title" defaultMessage="The ordes assigned to you will appear here"/>

  let cards = orders.map((order, i) => <OrderCard item={order} key={i} />)
  cards = cards.length ? cards : <Empty title={emptyTitleText} subtitle={emptySubTitleText}/> 

  return (
    <Container fluid className="main-content-container px-3 pt-3">
      <Row className="mb-4">
        <Col lg="8">

          { 
              offline
              ? <Offline />
              : (
                  isLoading ? <Loading /> : cards
                )
          }
        </Col>
      </Row>
    </Container>
  )
}

export default View
