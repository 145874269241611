import { useCallback, useContext, useMemo } from 'react'
import { OrdersStore, OrdersTypes } from '@Contexts/orders'
import { mergeOrders } from '@Utils/helper/iterators'
// import useOrders from './useOrders'
// import { mergeOrders } from '@Utils/helper/iterators'

const useTodo = () => {
    console.log('START USE_TODO')
    const ordersContext = useContext(OrdersStore)
    const ordersState = ordersContext.state
    const ordersDispatch = ordersContext.dispatch    

    // orders
    const orders = useMemo(() => {
        const validOrders = ordersState.orders.reduce((prev, curr) => {
            prev.push(curr)
            return prev
        }, [])
        return validOrders
    }, [ordersState])


    const loadOrders = useCallback(() => {
        let savedOrders = localStorage.getItem('shopping') || '{orders: []}'
        try {
            savedOrders = JSON.parse(savedOrders)
        } catch (e) {
            savedOrders = {orders: []}
        }
        const currentOrders = mergeOrders(ordersState.orders, savedOrders.orders) //[...ordersHook.orders, savedOrders.orders]

        // save local
        localStorage.setItem('shopping', JSON.stringify({orders: currentOrders}))

        ordersDispatch({type: OrdersTypes.SET_SHOPPING_ORDERS, payload: currentOrders})
    }, [ordersState.orders, ordersDispatch])


    const getOrderById = useCallback((id) => {
        id = parseInt(id)
        const [order] = orders.filter((x) => x.id === id)
        return order
    }, [orders])


    const updateTodo = useCallback((order_id, prop, value) => {
        order_id = parseInt(order_id)
        
        const result = orders.reduce((prev, curr) => {
            if(curr.id === order_id) curr.todo[prop] = value
            prev.push(curr)
            return prev
        }, [])

        // save local
        localStorage.setItem('shopping', JSON.stringify({orders: result}))

        ordersDispatch({type: OrdersTypes.UPDATE_SHOPPING_ORDER, payload: result})
    }, [orders, ordersDispatch])

    return {
        orders,
        getOrderById,
        updateTodo,
        loadOrders
    }
}

export default useTodo