import React from 'react'
import { Redirect } from 'react-router-dom'

// Layout Types
import { DefaultLayout, PublicLayout } from '@Layouts'

// Route Views
import Login from '@Views/login'
import Orders from '@Views/orders'
import ReceiptCheckout from '@Views/receiptCheck'
import DeliveryCheckout from '@Views/deliveryCheckout'
import Profile from '@Views/profile'
import OrderDetails from '@Views/orderDetail'
import Shopping from '@Views/shopping'
import ShoppingBatch from '@Views/shoppingBatch'

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/login" />
  },
  {
    path: "/orders",
    exact: true,
    layout: DefaultLayout,
    component: Orders
  },
  {
    path: "/receipt-check",
    exact: true,
    layout: DefaultLayout,
    component: ReceiptCheckout
  },
  {
    path: "/delivery-checkout",
    exact: true,
    layout: DefaultLayout,
    component: DeliveryCheckout
  },
  {
    path: "/profile",
    exact: true,
    layout: DefaultLayout,
    component: Profile
  },
  {
    path: "/orders/:id",
    exact: true,
    layout: DefaultLayout,
    component: OrderDetails
  },
  {
    path: "/orders/:id/shopping",
    exact: true,
    layout: DefaultLayout,
    component: Shopping
  },
  {
    path: "/orders/:id/shopping-batch",
    exact: true,
    layout: DefaultLayout,
    component: ShoppingBatch
  },
  
  // public
  {
    path: "/login",
    exact: true,
    layout: PublicLayout,
    component: Login
  },
  {
    layout: PublicLayout,
    component: () => <Redirect to="/login" />
  },
]
