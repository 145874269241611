import React from 'react'
import PropTypes from 'prop-types'
import { Container, Navbar } from 'shards-react'
import BottonItems from './bottomItems'
import styled from 'styled-components'

const BottomNavigation = () => {
  return (
    <BottomContainer className='main-navbar bg-white'>
      <Container className="p-0">
        <NavbarContainer type="light" className='align-items-stretch flex-md-nowrap p-0'>
          <BottonItems />
        </NavbarContainer>
      </Container>
    </BottomContainer>
  )
}

const BottomContainer = styled.div`
  position: fixed !important;
  bottom: 0 !important;
  width: 100%;
  z-index: 1010;
`

const NavbarContainer = styled(Navbar)`
  width: 100%;
  justify-content: space-evenly !important;
`

BottomNavigation.propTypes = {
  layout: PropTypes.string,
  stickyTop: PropTypes.bool
}

BottomNavigation.defaultProps = {
  stickyTop: true
}

export default BottomNavigation
