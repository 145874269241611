import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "shards-react"

import MainNavbar from "@Components/layout/navbar/navbar";
import UICore from "@Components/core/core";
import BottomNavigation from "@Components/layout/bottomNavigation/bottomNavigation";

import OfflineView from '@Components/layout/OfflineView'
import useApp from "@Hooks/useApp";
import NotAllowed from "@Components/core/notAllowed";
import ErrorView from "@Components/layout/ErrorView";
import { ErrorBoundary } from 'react-error-boundary'


const DefaultLayout = ({ children }) => {
  const { offline, compatible } = useApp()

  if (!compatible) return <NotAllowed />
  
  return (
    <Container fluid>
      <Row>
        <Col
          className="main-content p-0"
          lg={{ size: 10, offset: 2 }}
          md={{ size: 9, offset: 3 }}
          sm="12">
          <MainNavbar />
            <UICore auth>
              <ErrorBoundary FallbackComponent={ErrorView} >
                {
                  offline ? <OfflineView /> : children
                }
              </ErrorBoundary>


            </UICore>
          <BottomNavigation/>
        </Col>
      </Row>
    </Container>
)}

DefaultLayout.propTypes = {
  noNavbar: PropTypes.bool,
  noFooter: PropTypes.bool
}

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
}

export default DefaultLayout
