import { useCallback, useContext } from 'react'
import { AppStore, AppTypes } from '@Contexts/app'
// import { getLogin } from '@Utils/api'
import useApp from '@Hooks/useApp'
import jwtDecode from 'jwt-decode'
import useRequest from './useRequest'

const useAuth = () => {
    const { showAlert, setRedirect } = useApp()
    const { request, endpoints } = useRequest()

    const appContext = useContext(AppStore)
    const appState = appContext.state
    const appDispatch = appContext.dispatch

    // auth
    // const session = useCallback(() => appState.session, [appState.session])
    const session = appState.session

    const login = useCallback(async (payload) => {
        console.log('START LOGIN')
        const requestPayload = {
            method: 'post',
            url: endpoints.auth.login,
            data: {
                type: 'driver',
                provider: 'Normal',
                username: payload?.username,
                password: payload?.password
            }  
        }
        //const session = await login(payload)//await getLogin(payload)
        const session = await request(requestPayload)
        // const session = await getLogin(payload)
        if (session) {
            
            // get token decode
            let sessionPayload = jwtDecode(session.token)
            sessionPayload = {...sessionPayload, ...session}

            // set to localstorage
            localStorage.setItem('auth', JSON.stringify(sessionPayload))

            // dispatch
            appDispatch({type: AppTypes.SET_SESSION, payload: sessionPayload})
            
            // redirect
            setRedirect('/orders')

            return true
        }
        showAlert('INVALID_CREDENTIALS')
        return false
    }, [appDispatch, showAlert, setRedirect,request, endpoints])

    const loadSession = useCallback(() => {
        try {
            // get item from localstorage
            let auth = localStorage.getItem('auth')
            auth = JSON.parse(auth)
                
            // get token decode
            let sessionPayload = jwtDecode(auth.token)
            sessionPayload = {...sessionPayload, refresh_token: auth.refresh_token, token: auth.token}
            
    
            // set to localstorage
            localStorage.setItem('auth', JSON.stringify(sessionPayload))
    
            // dispatch
            appDispatch({type: AppTypes.SET_SESSION, payload: sessionPayload})

        } catch (err) {
            setRedirect('/login')
        }

    }, [appDispatch, setRedirect])

    const logout = useCallback(() => {
        // dispatch
        localStorage.removeItem('token')
        localStorage.removeItem('auth')
        loadSession()

    }, [loadSession])

    return {
        session,
        login,
        loadSession,
        logout
    }
}

export default useAuth