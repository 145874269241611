import React, { useContext } from 'react'
import { Button } from 'shards-react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'

const ButtonComponent = ({ children, isLoading, onClick }) => {
    const appState = useContext(AppStore)
    return (
        <ButtonLogin $state={appState.state} onClick={onClick} block type="submit" disabled={isLoading}>
            { 
                isLoading 
                ? <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                </>
                : children
            }
        </ButtonLogin>
    )
}

const ButtonLogin = styled(Button)`
    font-weight: bold !important;
    padding: 0.75rem 1rem !important;
    ${({$state}) => `
        background-color: ${$state.colors.primary} !important;
        border: 1px solid ${$state.colors.primary} !important`}
`

ButtonComponent.propTypes = {
    isLoading: PropTypes.bool
}
  
ButtonComponent.defaultProps = {
    isLoading: false
}

export default ButtonComponent