module.exports = [
  {
    title: "Home",
    to: "/orders",
    icon: 'home',
    htmlBefore: '<i class="material-icons">home</i>',
    htmlAfter: ""
  },
  {
    title: "Receipt Check",
    to: "/receipt-check",
    icon: 'receipt_long',
    htmlBefore: '<i class="material-icons">home</i>',
    htmlAfter: ""
  },
  {
    title: "Delivery Checkout",
    to: "/delivery-checkout",
    icon: 'check',
    htmlBefore: '<i class="material-icons">home</i>',
    htmlAfter: ""
  },
  {
    title: "Profile",
    to: "/profile",
    icon: 'person',
    htmlBefore: '<i class="material-icons">home</i>',
    htmlAfter: ""
  },
    
]
  