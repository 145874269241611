import { useCallback, useContext, useMemo } from 'react'
import { OrdersStore, OrdersTypes } from '@Contexts/orders'
import useOrders from './useOrders'
import { mergeOrders } from '@Utils/helper/iterators'

const useShopping = () => {
    console.log('START USE_SHOPPING')
    const ordersContext = useContext(OrdersStore)
    const ordersState = ordersContext.state
    const ordersDispatch = ordersContext.dispatch
    const ordersHook = useOrders()

    // orders
    const orders = useMemo(() => ordersState.orders, [ordersState])

    const loadOrders = useCallback(() => {
        let savedOrders = localStorage.getItem('shopping') || '{orders: []}'
        try {
            savedOrders = JSON.parse(savedOrders)
        } catch (e) {
            savedOrders = {orders: []}
        }
        const currentOrders = mergeOrders(ordersHook.orders, savedOrders.orders) //[...ordersHook.orders, savedOrders.orders]

        // save local
        localStorage.setItem('shopping', JSON.stringify({orders: currentOrders}))

        ordersDispatch({type: OrdersTypes.SET_SHOPPING_ORDERS, payload: currentOrders})
    }, [ordersHook.orders, ordersDispatch])

    const getOrderById = useCallback((id) => {
        id = parseInt(id)
        const [order] = orders.filter((x) => x.id === id)
        return order
    }, [orders])

    const updateStatus = useCallback((order_id, product_id, status) => {
        order_id = parseInt(order_id)
        product_id = parseInt(product_id)
        
        const result = orders.reduce((prev, curr) => {
            if(curr.id === order_id) {
                const products = curr.products.reduce((prev2, curr2) => {
                    if(curr2.id === product_id) curr2.status = status
                    prev2.list.push(curr2)

                    if (curr2.status === '') {
                        console.log('IS_EMPTY_____')
                        prev2.rest = true
                    }
                    return prev2
                }, {list:[], rest: false})

                curr.products = products.list

                console.log('REST', products.rest)
                curr.todo.checkout = !products.rest
            }
            prev.push(curr)
            return prev
        }, [])

        // save local
        localStorage.setItem('shopping', JSON.stringify({orders: result}))

        ordersDispatch({type: OrdersTypes.UPDATE_SHOPPING_ORDER, payload: result})
    }, [orders, ordersDispatch])

    return {
        orders,
        getOrderById,
        updateStatus,
        loadOrders
    }
}

export default useShopping