import { mergeOrders } from '@Utils/helper/iterators'
import types from './orders.types'

const reducer = (state, action) => {
    console.log(action)
        
    switch(action.type) {
      //
      case types.SET_ORDERS:
        return { ...state, orders: mergeOrders(state.orders, action.payload) }
      // 
      //
      case types.UPDATE_ORDERS:
        return { ...state, orders: action.payload }
      //
      //
      case types.SET_SHOPPING_ORDERS:
        return { ...state, orders: action.payload }
      // 
      //
      case types.UPDATE_SHOPPING_ORDER:
        return { ...state, orders: action.payload }
      // 
      default:
        return state
    }
  }

  export default reducer