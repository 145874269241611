import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react"
import Empty from "@Components/layout/Empty";
import MainReceiptCheckout from "@Components/receiptCheck/main";
import Loading from "@Components/layout/loading";
import useApp from "@Hooks/useApp";
import useCheckout from "@Hooks/useCheckout";
import { FormattedMessage } from "react-intl";

const view = {
  title: <FormattedMessage id="view.title.receipt_check" defaultMessage="Receipt Check"/>,
  shadow: true,
  left_visible: false
}

const View = () => {
  const { setNavbar } = useApp()
  const { orders, loadOrders } = useCheckout()
  const [isLoading, setLoading] = useState(!orders.length)

  useEffect(() => {
    if (isLoading) {
      loadOrders()
    }
    
    setNavbar(view)
    setLoading(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbar])

  const isEmpty = !orders.length
  // texts
  const emptyTitleText = <FormattedMessage id="empty.receipt_check.title" defaultMessage="No receipt check"/>
  const emptySubTitleText = <FormattedMessage id="empty.receipt_check.sub_title" defaultMessage="The ordes assigned to you will appear here"/>

  return (
    <Container fluid className="main-content-container px-3 pt-3">
      <Row className="mb-4">
        <Col lg="8">
          
          { isLoading
            ? <Loading />
            : (
                isEmpty 
                ? <Empty title={emptyTitleText} subtitle={emptySubTitleText}/> 
                : <MainReceiptCheckout />
              )
          }
          
        </Col>
      </Row>
    </Container>
  )
}

export default View
