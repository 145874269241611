import React from 'react'
import { Nav } from 'shards-react'
import BottomItem from './bottomItem'
import styled from 'styled-components'
import useApp from '@Hooks/useApp'


const BottomNavItems = () => {
  const { bottomNavigation } = useApp()

  const renderItems = bottomNavigation.map((item, i) => <BottomItem key={i} item={item} />)

  return (
    <BottomNav className='nav--no-borders flex-row'>
      { renderItems }
    </BottomNav>
  )
}

const BottomNav = styled(Nav)`
  width: 100%;
  justify-content: space-evenly !important;
`

export default BottomNavItems
