import { useCallback, useContext } from 'react'
import { AppStore, AppTypes } from '@Contexts/app'
import getAlert from "@Config/alert"

const useApp = () => {
    const appContext = useContext(AppStore)
    const appState = appContext.state
    const appDispatch = appContext.dispatch

    // lang
    const lang = appState.lang

    const setLang = useCallback((payload) => {
        localStorage.setItem('lang', payload)
        appDispatch({type: AppTypes.SET_LANG, payload})
    }, [appDispatch])

    // error
    const hasError = appState.hasError

    const setError = useCallback((payload) => {
        appDispatch({type: AppTypes.SET_ERROR, payload})
    }, [appDispatch])


    // compatible
    const compatible = appState.compatible || window.innerWidth < 767

    const setCompatible = useCallback((payload) => {
        appDispatch({type: AppTypes.SET_COMPATIBLE, payload})
    }, [appDispatch])

    // navbar
    const navbar = appState.navbar

    const setNavbar = useCallback((payload) => {
        appDispatch({type: AppTypes.SET_NAVBAR, payload})
    }, [appDispatch])

    // bottom navigation
    const bottomNavigation = appState.bottom_navigation.items
    
    //alerts
    const alert = appState.alert

    const showAlert = useCallback((alertName, payload) => {
        appDispatch({type: AppTypes.SHOW_ALERT, payload: getAlert(alertName, payload)})
    }, [appDispatch])

    const hideAlert = useCallback(() => {
        appDispatch({type: AppTypes.HIDE_ALERT})
    }, [appDispatch])

    // redirects
    const goto = appState.goto

    const setGoto = useCallback((to) => {
        appDispatch({type: AppTypes.GOTO, payload: to})
    }, [appDispatch])

    const redirect = appState.redirect

    const setRedirect = useCallback((to) => {
        appDispatch({type: AppTypes.REDIRECT, payload: to})
    }, [appDispatch])


    // modals
    const modal = appState.modal

    const showModal = useCallback((payload) => {
        appDispatch({type: AppTypes.SHOW_MODAL, payload})
    }, [appDispatch])

    const hideModal = useCallback(() => {
        appDispatch({type: AppTypes.HIDE_MODAL})
    }, [appDispatch])


    // offline
    const offline = appState.offline

    const setOffline = useCallback((value) => {
        appDispatch({type: AppTypes.SET_OFFLINE, payload: value})
    }, [appDispatch])


    return {
        lang,
        setLang,

        compatible,
        setCompatible,
        
        navbar,
        setNavbar,

        bottomNavigation,

        alert,
        showAlert,
        hideAlert,

        goto,
        setGoto,
        redirect,
        setRedirect,

        modal,
        hideModal,
        showModal,

        offline,
        setOffline,

        hasError,
        setError
    }
}

export default useApp