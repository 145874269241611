import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouteNavLink } from 'react-router-dom'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'

const BottomNavItem = ({ item }) => {
  const AppState = useContext(AppStore)
  const [active, setActive] = useState(false)
  const xx = useRef(false)

  useEffect(() => {
   setActive(xx.current.classList.contains('active'))
  }, [setActive])
  return (
    <RouteNavLink ref={xx}
      to={item.to} className={`nav-link nav-link-icon d-sm-inline d-md-inline d-lg-none text-center`}>
      <Icon $active={active} $state={AppState.state} className='material-icons'>{item.icon}</Icon>
    </RouteNavLink>
)}

const Icon = styled.i`
  padding-left: 10px;
  position: inherit !important;
  font-weight: 600;

  color: ${(props) => props.$active
    ? props.$state.colors.primary
    : props.$state.colors.dark
  };
`

BottomNavItem.propTypes = {
  item: PropTypes.object
}

export default BottomNavItem
