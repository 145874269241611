import React, { useContext } from "react";
import { CardTitle, CardSubtitle } from "shards-react"
import offlineImage from '@Assets/images/layout/offline.png'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'
import { FormattedMessage } from "react-intl";

const imgOffline = new Image()
imgOffline.src = offlineImage

const Offline = ({title, subtitle, style}) => {
  // texts
    let texts = {
      "app.offline.title": "No connection",
      "app.offline.sub_title": "You don't have connection"
    }

  Object.keys(texts).map((x) => texts[x] = <FormattedMessage id={x} defaultMessage={texts[x]}/>)
  // end texts
  const appState = useContext(AppStore)
  return (
    <OfflineContainer style={style}>
      <Card>
        <Photo $src={offlineImage} />
        <Title $state={appState.state}> {texts["app.offline.title"]} </Title>
        <Subtitle $state={appState.state}> {texts["app.offline.sub_title"]} </Subtitle>
      </Card>
    </OfflineContainer>
  )
}

const OfflineContainer = styled.div`
  height: calc(100vh - 170px);
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
`

const Card = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Title = styled(CardTitle)`
  font-size: 22px;
  font-weight: normal !important;
  margin-bottom: 16px !important;
  color: ${({$state}) => $state.colors.gray_2} !important;
`

const Subtitle = styled(CardSubtitle)`
  font-size: 14px;
  color: ${({$state}) => $state.colors.gray_2} !important;
`

const Photo = styled.div`
  width: 150px !important;
  height: 150px !important;
  background-image: url(${({$src}) => $src});
  background-size: contain;
`

export default Offline
