import { EventEmitter } from "events"
import Dispatcher from "./dispatcher"
import Constants from "./constants"
import BottomItems from "@Config/navItems"
import getAlert from "@Config/alert"
import { getSession, deleteSession, setSession, getVersion, setVersion } from '@Utils/auth'
import { mergeItem, addItemsFilter, setShoppingById, getShoppingById, getFilteredToDo, setToDoById, getFilteredShopping } from "@Utils/helper"
import { getOrders, updateOrderById, postCheckout } from "@Utils/api"

// Store
let _store = {
  loaded: false,
  modules_loaded: {
    orders: false,
    items: false,
    todo: false
  },

  navbar: {
    shadow: true,
    left_visible: false,
    title: 'Uride for Work',
  },
  bottom_navigation: {
    items: BottomItems,
  },
  modal: {
    action: () => null,
    visible: false,
    text: 'Are you sure you want to do this?'
  },
  offline: false,
  todo: {}, // local
  orders: [], // from API
  products: {}, // from API
  to_shopping: {}, // local
  alert: getAlert('DEFAULT'),
  session: getSession(),
  version: '0.0.3'
};

class Store extends EventEmitter {
  constructor () {
    super()
    // this.registerToActions = this.registerToActions.bind(this)
    // this.toggleSidebar = this.toggleSidebar.bind(this)
    // this.toggleAlert = this.toggleAlert.bind(this)
    Dispatcher.register(this.registerToActions.bind(this))
  }

  registerToActions ({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar()
        break
      case Constants.SHOW_ALERT:
        this.toggleAlert()
        break
      default:
    }
  }

  // ----- Listeners
  navbarListener (action, cb) {
    if (action === 'add') return this.on(Constants.CHANGE, cb)
    if (action === 'remove')return this.removeListener(Constants.CHANGE, cb)
  }

  alertListener (action, cb) {
    if (action === 'add') return this.on(Constants.SHOW_ALERT, cb)
    if (action === 'remove')return this.removeListener(Constants.SHOW_ALERT, cb)
  }

  // ----- Sidebar Actions
  toggleSidebar () {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }

  getMenuState () {
    return _store.menuVisible;
  }

  getSidebarItems () {
    return _store.navItems;
  }

  // nav 
  getNavbar () {
    return _store.navbar
  }

  setNavbar (payload) {
    _store.navbar = Object.assign(_store.navbar, payload)
  }

  // botom Navigation
  getBottomItems () {
    return _store.bottom_navigation.items
  }

  // ----- Alert Actions
  toggleAlert () {
    _store.alert.visible = !_store.alert.visible
    this.emit(Constants.SHOW_ALERT);
  }

  getAlert () {
    return _store.alert;
  }

  showAlert (alertName, payload) {
    _store.alert = getAlert(alertName, payload)
    this.emit(Constants.SHOW_ALERT);
  }

  hideAlert () {
    _store.alert.visible = false
    this.emit(Constants.SHOW_ALERT);
  }

  // ----- Session Actions
  setSession (payload) {
    _store.session = payload
    // this.emit(Constants.SESSION);
  }

  loginSession (token) {
    setSession(token)
    this.reloadSession()
    // this.emit(Constants.SESSION);
  }

  getSession () {
    return _store.session;
  }

  removeSession () {
    deleteSession()
    this.reloadSession()
  }

  reloadSession () {
    _store.session = getSession()
  }

  // CORE

  redirectListener (action, cb) {
    if (action === 'add') return this.on(Constants.REDIRECT, cb)
    if (action === 'remove')return this.removeListener(Constants.REDIRECT, cb)
  }

  gotoListener (action, cb) {
    if (action === 'add') return this.on(Constants.GOTO, cb)
    if (action === 'remove')return this.removeListener(Constants.GOTO, cb)
  }

  redirect (to) {
    this.emit(Constants.REDIRECT, to);
  }

  goto (to) {
    this.emit(Constants.GOTO, to);
  }

  initialize (auth, history) {
    const path = history ? history.location.pathname || '/orders' : '/orders'

    window.addEventListener('storage', (e) => {
      this.reloadSession()
      this.initialize(auth)
    })

    // version 
    const version = getVersion()
    if (version !== _store.version) {
      this.removeSession()
      setVersion(_store.version)
    }

    if (auth) {
      if(!_store.session) {
        this.emit(Constants.REDIRECT, '/login')
      } else {
        if(_store.session) this.emit(Constants.REDIRECT, path)
      }
    } else {
      if(_store.session) this.emit(Constants.REDIRECT, '/orders')
    }    
  }

  // ----- Orders Actions

  ordersListener (action, cb) {
    if (action === 'add') return this.on(Constants.SET_ORDERS, cb)
    if (action === 'remove')return this.removeListener(Constants.SET_ORDERS, cb)
  }

  setOrders (payload) {
    _store.orders = payload
    _store.to_shopping = getFilteredShopping(_store.orders)

    this.todoList().load()
    _store.loaded = true

    this.emit(Constants.SET_ORDERS)
    this.emit(Constants.TO_SHOPPING)
  }

  getOrders () {
    return _store.orders
  }

  getOrder (id) {
    id = parseInt(id)
    const order = _store.orders.reduce((prev, current) => {
      if (current.id === id) return current
      return prev
    }, null)
    return order
  }

  getOrderByBatch (id) {
    id = parseInt(id)
    const order = _store.orders.reduce((prev, current) => {
      if (current.batch_id === id) prev.push(current)
      return prev
    }, [])
    return order
  }

  async statusOrder (id, value) {
    id = parseInt(id)
    await updateOrderById(id, { status_code: value })
    // _store.orders[index] = result
    if (value === 5) this.todoList().check_picked_by_driver(id)
    if (value === 6) this.todoList().check_delivered(id)
    //
    const index = _store.orders.findIndex((x) => x.id === id)
    _store.orders[index].status.status_code = value
    this.emit(Constants.SET_ORDERS)
  }

    // ----- Products Actions

  productsListener (action, cb) {
    if (action === 'add') return this.on(Constants.PRODUCTS, cb)
    if (action === 'remove')return this.removeListener(Constants.PRODUCTS, cb)
  }

  setProducts (order, payload) {
    _store.products[order] = payload
    this.emit(Constants.PRODUCTS)
  }

  getProducts () {
    return _store.products
  }

  getProduct (id) {
    return _store.products[id] || []
  }

  // ----- To Shopping Actions

  toShoppingListener (action, cb) {
    if (action === 'add') return this.on(Constants.TO_SHOPPING, cb)
    if (action === 'remove')return this.removeListener(Constants.TO_SHOPPING, cb)
  }

  setToShopping (order, payload) {
    _store.to_shopping[order] = payload
    this.emit(Constants.TO_SHOPPING)
  }

  async loadToShopping () {
    if (_store.orders.length === 0 && !_store.loaded) await getOrders()
    _store.loaded = true
  }

  async initToShopping (order) {
    // init from local
    const local = getShoppingById(order)

    if (Object.keys(local).length) {
      _store.to_shopping[order] = local
      return this.emit(Constants.TO_SHOPPING)
    }
    //
    if (!_store.orders.length) await getOrders()
    const user = _store.orders.filter((x) => x.id === parseInt(order) )[0].user

    _store.to_shopping[order] = {
      id: order,
      name: `${user.name} ${user.last_name}`,
      keep_bill: false,
      bags: -1,
      checkout: false,
      items: addItemsFilter(_store.products[order])
    }

    setShoppingById(order, _store.to_shopping[order])
    this.emit(Constants.TO_SHOPPING)
  }

  getToShoppingAll () {
    return _store.to_shopping
  }

  getToShopping (id) {
    return _store.to_shopping[id] || []
  }

  addItemNotFound (order, item) {
    // if (!_store.to_shopping[order]) this.initToShopping(order)
    mergeItem(_store.to_shopping[order].items, item)
    setShoppingById(order, _store.to_shopping[order])
    this.emit(Constants.TO_SHOPPING)
  }

  addItemFound (order, item) {
    // if (!_store.to_shopping[order]) this.initToShopping(order)
    mergeItem(_store.to_shopping[order].items, item)
    setShoppingById(order, _store.to_shopping[order])
    this.emit(Constants.TO_SHOPPING)
  }

  setCheckout (order, value = true) {
    _store.to_shopping[order].checkout = value

    setShoppingById(order, _store.to_shopping[order])
    this.todoList().check_items_grouped(order)
    this.emit(Constants.RECEIPT_CHECKOUT)
  }

  // Receipt Checkout

  receiptCheckoutListener (action, cb) {
    if (action === 'add') return this.on(Constants.RECEIPT_CHECKOUT, cb)
    if (action === 'remove')return this.removeListener(Constants.RECEIPT_CHECKOUT, cb)
  }

  receipt (order) { 
    return {
      setBags : (value) => {
        _store.to_shopping[order].bags = value
        setShoppingById(order, _store.to_shopping[order])
        this.receipt(order).check_validation()
        this.emit(Constants.RECEIPT_CHECKOUT)
      },

      keep_receipt: (value = true) => {
        _store.to_shopping[order].keep_bill = value
        setShoppingById(order, _store.to_shopping[order])
        this.receipt(order).check_validation()
        this.emit(Constants.RECEIPT_CHECKOUT)
      },

      check_validation: async () => {
        const item = _store.to_shopping[order]
        if (item.bags >= 0 && item.keep_bill) {
          const data = _store.to_shopping[order].items.reduce((prev, current) => {
            if (current.is_found) {
              prev.found.push(current.id)
            } else {
              prev.not_found.push(current.id)
            }
            return prev
          }, { found: [], not_found: [] })
          //
          await postCheckout(data)
          //
          this.todoList().check_items_shopped(order)
        }
      }
    }
  }

  // Modal

  modaltListener (action, cb) {
    if (action === 'add') return this.on(Constants.SHOW_MODAL, cb)
    if (action === 'remove')return this.removeListener(Constants.SHOW_MODAL, cb)
  }

  modal () { 
    return {
      show : (payload = {}) => {
        Object.assign(_store.modal, payload)
        _store.modal.visible = true
        this.emit(Constants.SHOW_MODAL)
      },

      hide: () => {
        _store.modal.visible = false
        this.emit(Constants.SHOW_MODAL)
      },

      getModal: () => {
       return _store.modal
      }
    }
  }


    // TODO

  todoListener (action, cb) {
    if (action === 'add') return this.on(Constants.TODO, cb)
    if (action === 'remove')return this.removeListener(Constants.TODO, cb)
  }
  
  todoList () {
    return {
      load: async () => {
        if (_store.orders.length === 0 && !_store.loaded) await getOrders()
        _store.loaded = true

        let items = getFilteredToDo(_store.orders)

        _store.orders.map((current) => {
          if (!Object.keys(items[current.id] || {}).length) {
            const payload = this.todoList().initialize(current)
            setToDoById(current.id, payload)
          }
          return current.id
        })

        _store.todo = getFilteredToDo(_store.orders)
        this.emit(Constants.TODO)
      },

      initialize: (item) => {
        return {
          id: item.id,
          // name: `${item.user.name} ${item.user.last_name}`,
          name: `${item.user_name} ${item.user_last_name}`,
          order_assigned: true,
          items_grouped: false,
          items_shopped: false,
          picked_by_driver: false,
          delivered: false,
          // left_feedback: false // todo
        }
        
      },

      getAll: () => {
        return _store.todo
      },

      getById: (id) => {
        return _store.todo[id] || {}
      },

      check_items_grouped: (id) => {
        _store.todo[id].items_grouped = true
        setToDoById(id, _store.todo[id])
        this.emit(Constants.TODO)
      },

      check_items_shopped: (id) => {
        _store.todo[id].items_shopped = true
        setToDoById(id, _store.todo[id])
        this.emit(Constants.TODO)
      },

      check_picked_by_driver: (id) => {
        _store.todo[id].picked_by_driver = true
        setToDoById(id, _store.todo[id])
        this.emit(Constants.TODO)
      },

      check_delivered: (id) => {
        _store.todo[id].delivered = true
        setToDoById(id, _store.todo[id])
        this.emit(Constants.TODO)
      },

    }
  }

    // OFFLINE

  offlineListener (action, cb) {
    if (action === 'add') return this.on(Constants.OFFLINE, cb)
    if (action === 'remove')return this.removeListener(Constants.OFFLINE, cb)
  }

  setOffline (value = true) {
    console.log('SET', value)
    _store.offline = value
    this.emit(Constants.OFFLINE)
  }

  getOffline () {
    return _store.offline
  }



}

export default new Store()
