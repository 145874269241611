import React, { useContext, useState } from 'react'
import { Card, CardBody, CardTitle, CardFooter, Button, Badge } from 'shards-react'
import PropTypes from 'prop-types'
import { Store } from '@Store'
import Modal from './modal'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'

const ProductCard = ({item, status}) => {
    const appState = useContext(AppStore)
    const [visible, setVisible] = useState(false)

    const imageOnlick = () => {
        setVisible(true)
    }

    const onCloseModal = () => {
        setVisible(false)
    }

    const buildItem = () => {
        const { id, price, quantity, product, category } = item
        return {
            id: id || 0,
            price: `$${parseFloat(price).toFixed(2)}` || '$0.00',
            quantity: quantity || 1,
            category_name: category.name || '',
            name: product.name || '',
            image: product.image_url || '',
        }
    }

    const { id, price, quantity, category_name, name, image } = buildItem()
    const { is_checked, is_found } = status
    
    const onNotFound = () => {
        const payload = {
            id: id,
            is_checked: true
        }
        Store.addItemNotFound(item.order_id, payload)
    }

    const onFound = () => {
        const payload = {
            id: id,
            is_found: true,
            is_checked: true
        }
        Store.addItemNotFound(item.order_id, payload)
    }

    const footer2 = (
        <Footer>
            { 
                is_found 
                ?
                    <Pill $state={appState.state} pill theme="success">
                        <IconModal className='material-icons'>check</IconModal> Found
                    </Pill>
                :
                    <Pill $state={appState.state} pill theme="danger">
                        <IconModal className='material-icons'>close</IconModal> NotFound
                    </Pill>
            }
        </Footer>
    )

    const footer = (
        <Footer $checked>
            <ButtonAction $color='light' $state={appState.state} theme="ligth" onClick={onNotFound} >
                <Icon $dark className='material-icons'>close</Icon> NotFound
            </ButtonAction>
            <ButtonAction $color='primary' $state={appState.state} theme="success" onClick={onFound}>
                <Icon className='material-icons'>check</Icon> Found
            </ButtonAction>
        </Footer>
    )

    return (
        <>
            <Modal image={image} visible={visible} onClose={onCloseModal} />
            <Card className="mb-3">
                <Body>
                    <Content>
                        <Title className='mb-1'> {name} </Title>

                        <Text> <b>Category:</b> {category_name} </Text>
                        <Text> <b>Price:</b> {price} </Text>
                        <Text> <b>Quantity:</b> {quantity}</Text>

                    </Content>
                    <Photo $src={image} onClick={imageOnlick} />
                </Body>
                { is_checked ? footer2 : footer}
            </Card>
        </>
    )
}

const Footer = styled(CardFooter)`
    padding: 14px !important;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 0px !important;    

    ${({$checked}) => !$checked && `min-height: 54px;`}
`

const Pill = styled(Badge)`
    ${(props) => 
        props.theme === 'success' && `background-color: ${props.$state.colors.primary} !important;`
    }
`

const IconModal = styled.i`
    font-size: 20px !important;
    font-weight: 700 !important;
`

const Icon = styled.i`
    font-weight: 900;
    margin-right: 4px;
    color: ${({$state}) => $state.colors.white};

    ${(props) => props.$dark && `color: ${props.$state.colors.dark_2} !important;`}
`

const ButtonAction = styled(Button)`
    margin-left: 8px;
    padding: 9px !important;
    border-radius: 8px !important;

    ${(props) => props.$color === 'light' && `background-color: ${props.$state.colors.light_2} !important;`}
    ${(props) => props.$color === 'primary' && `background-color: ${props.$state.colors.primary} !important;`}
`

const Body = styled(CardBody)`
    padding: 14px !important;
    padding-bottom: 8px !important;
    display: flex;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 4px;
    flex: 1;
`

const Title = styled(CardTitle)`
    font-size: 18px !important;
    margin-bottom: 14px !important;
    font-weight: 700 !important;
`

const Text = styled(CardTitle)`
    margin-top: -4px;
    margin-bottom: 0 !important;
`

const Photo = styled.div`
    width: 60px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;

    background-image: url(${({$src}) => $src});
    background-size: cover;
    background-position: center;
`

ProductCard.propTypes = {
    item: PropTypes.object
  }
  
  ProductCard.defaultProps = {
    item: {
        id: 1,
        price: '0.00',
        quantity: 1,
        store_name: 'walmart',
        product: {
            name: 'Product Name',
            image_url: 'https://storage.googleapis.com/ur-files/webimages/upload/MenuItem/1592866532_76335.jpg'
        }
    }
  }

export default ProductCard
