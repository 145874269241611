import React, { useContext } from 'react'
import { CardTitle, FormSelect } from 'shards-react'
import ButtonLogOut from './button'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'
import useAuth from '@Hooks/useAuth'
import { FormattedMessage, useIntl } from 'react-intl'
import useApp from '@Hooks/useApp'

const MainProfile = (props) => {
    const { session, logout } = useAuth()
    const appState = useContext(AppStore)
    const { lang, setLang } = useApp()
    const intl = useIntl()

    const user = {
        name: `${session.first_name} ${session.last_name}`,
        phone: `+${session.phone_code} ${session.phone}`,
        email: session.email,
        photo: session.photo,
    }

    const { name, phone, email, photo } = user

    const onClick = () => logout()

    const onChange = (e) => setLang(e.target.value || lang)

    // texts
    let texts = {
        "profile.email": "Email",
        "profile.phone": "Phone",
        "profile.language": "Language"
    }

    Object.keys(texts).map((x) => texts[x] = <FormattedMessage id={x} defaultMessage={texts[x]}/>)
    // end texts

    let langs = {
        "profile.en": "English",
        "profile.es": "Spanish"
    }

    Object.keys(langs).map((x) => langs[x] = intl.formatMessage({id: x, defaultMessage: langs[x]}) )

    return (
        <>
            <Holder $state={appState.state}>
                <Photo $src={photo} />
                <Title $state={appState.state}>{name}</Title>
            </Holder>
            <div className="my-5">
                <p className="mb-0"> <b> {texts["profile.email"]} </b> </p>
                <p className="mb-2"> {email} </p>

                <p className="mb-0"> <b> {texts["profile.phone"]} </b> </p>
                <p className="mb-2"> {phone} </p>

                <p className="mb-2"> <b> {texts["profile.language"]} </b> </p>
                <Select value={lang} onChange={onChange} $state={appState.state}>
                    <option value="en"> {langs["profile.en"]} </option>
                    <option value="es"> {langs["profile.es"]} </option>
                </Select>

                <ButtonLogOut onClick={onClick} />
            </div>
        </>
    )
}

const Holder = styled.div`
    width: calc(100% + 32px);
    height: 250px;
    margin-top: -16px;
    margin-left: -16px;
    margin-right: -16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({$state}) => $state.colors.primary};
`

const Photo = styled.div`
    width: 132px;
    height: 132px;
    border: 5px solid #FFF;
    border-radius: 100%;
    overflow: hidden;
    text-align: center;
    background-color: #FFF;
    background-image: url(${({$src}) => $src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-origin: border-box;
`

const Title = styled(CardTitle)`
    font-size: 20px !important;
    margin-top: 20px !important;
    margin-bottom: 0px !important;
    font-weight: 700 !important;
    color: ${({$state}) => $state.colors.white};
`

const Select = styled(FormSelect)`
    max-width: 150px;
    font-size: 1rem;
    font-weight: 400;
    &:focus {
        border-color: ${({$state}) => $state.colors.primary} !important;
    }
`


export default MainProfile
