import React from 'react'
import { CardTitle } from 'shards-react'
import PropTypes from 'prop-types'
import Item from './item'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'



const CheckList = ({item}) => {
    const user = item.user
    const todo = item.todo

    // texts
    let texts = {
        "delivery_checkout.order_assigned": "Order assigned",
        "delivery_checkout.items_grouped": "Group items for checkout",
        "delivery_checkout.items_shopped": "Items shopped",
        "delivery_checkout.picked_up": "Picked up by Driver",
        "delivery_checkout.delivered": "Delivered"
    }

    Object.keys(texts).map((x) => texts[x] = <FormattedMessage id={x} defaultMessage={texts[x]}/>)
    // end texts

    return (
        <div className="mb-4 pt-3">
            <Title className='mb-2'> {user.first_name} {user.last_name} </Title>
            <Item text={texts["delivery_checkout.order_assigned"]} completed={todo.assigned}/>
            <Item text={texts["delivery_checkout.items_grouped"]} completed={todo.checkout}/>
            <Item text={texts["delivery_checkout.items_shopped"]} completed={todo.shopped}/>
            <Item text={texts["delivery_checkout.picked_up"]} completed={todo.picked_up}/>
            <Item text={texts["delivery_checkout.delivered"]} completed={todo.delivered}/>
        </div>
    )
}

const Title = styled(CardTitle)`
    font-size: 18px !important;
    margin-bottom: 14px !important;
    font-weight: 700 !important;
`

CheckList.propTypes = {
    item: PropTypes.object
  }
  
CheckList.defaultProps = {
    item: {
        id: 1,
        name: 'Test Name',
        order_assigned: true,
        items_grouped: false,
        items_shopped: false,
        picked_by_driver: false,
        delivered: false,
    }
}

export default CheckList
