import React from "react"
import { Container, Row, Col } from "shards-react"
import Offline from './Offline'

const OfflineView = () => {
    return (
        <Container fluid className="main-content-container px-3 pt-3">
          <Row className="mb-4">
            <Col lg="8">
                <Offline />
            </Col>
          </Row>
        </Container>
      )
}

export default OfflineView