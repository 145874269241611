import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react"
import MainCheckList from "@Components/deliveryCheckout/main";
import Loading from "@Components/layout/loading";
import Empty from "@Components/layout/Empty";
import useApp from "@Hooks/useApp";
import useTodo from "@Hooks/useTodo";
import { FormattedMessage } from "react-intl";

const view = {
  title:  <FormattedMessage id="view.title.delivery_checkout" defaultMessage="Delivery Checkout"/>,
  shadow: true,
  left_visible: false
}

const View = () => {
  const { setNavbar } = useApp()
  const { orders, loadOrders } = useTodo()
  const [isLoading, setLoading] = useState(!orders.length)

  useEffect(() => {
    setNavbar(view)
    if (isLoading) loadOrders()
    setLoading(false)
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbar])

  const isEmpty = !orders.length
  // texts
  const emptyTitleText = <FormattedMessage id="empty.orders.title" defaultMessage="No orders assined"/>
  const emptySubTitleText = <FormattedMessage id="empty.orders.sub_title" defaultMessage="The ordes assigned to you will appear here"/>


  return (
    <Container fluid className="main-content-container px-3 pt-3">
      <Row className="mb-4">
        <Col lg="8">
          { 
            isLoading
            ? <Loading />
            : (
                isEmpty
                ? <Empty title={emptyTitleText} subtitle={emptySubTitleText}/> 
                : <MainCheckList />
              )
          }
        </Col>
      </Row>
    </Container>
  )
}

export default View
