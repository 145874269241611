import React, { useContext } from 'react'
import { Button } from 'shards-react'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'
import { FormattedMessage } from 'react-intl'

const ButtonLogOut = ({ onClick }) => {
    const appState = useContext(AppStore)
    // texts
    let texts = {
        "profile.logout": "Logout"
    }

    Object.keys(texts).map((x) => texts[x] = <FormattedMessage id={x} defaultMessage={texts[x]}/>)
    // end texts
    return ( <ButtonLogout $state={appState.state} theme="light" onClick={onClick} block type="submit"> {texts["profile.logout"]} </ButtonLogout> )
}

const ButtonLogout = styled(Button)`
    background-color: ${({$state}) => $state.colors.light} !important;
    padding: 0.75rem 1rem !important;
    max-width: 320px;
    font-weight: 700 !important;
    margin: auto;
    border-radius: 4px;
    margin-top: 75px;
`

export default ButtonLogOut