import { request } from '../axios'
import { api } from '@Config/config'
import { Store } from '@Store'

const { login, shopping } = api

const endpoints = {
    login: `${login}/v2/auth/login`,
    orders: `${shopping}/v2/orders`,
    checkout: `${shopping}/v2/orders/checkout-review`
}

const getLogin = async (payload = {}) => {
    try {
        const { password, username } = payload
        if (!password || !username) return Store.showAlert('ERROR')

        payload = {...payload, type: 'driver', provider: 'Normal'}
        const requestPayload = {
            url: endpoints.login,
            method: 'post',
            data: payload
        }
        const result = await request(requestPayload)
        // Store.loginSession(result.token)
        // Store.redirect('/home')
        return result
    } catch (err) {
        // Store.showAlert('INVALID_CREDENTIALS')
        return false
    }
}

const getOrders = async () => {
    try {
        const requestPayload = {
            url: endpoints.orders,
            method: 'get',
            // data: payload
        }
        const result = await request(requestPayload)
        Store.setOrders(result.items)
        return result
    } catch (err) {
        Store.showAlert('ERROR')
        return false
    }
}

const getOrderById = async (id) => {
    try {
        const requestPayload = {
            url: `${endpoints.orders}/${id}`,
            method: 'get',
        }
        const result = await request(requestPayload)
        return result
    } catch (err) {
        Store.showAlert('ERROR')
        return false
    }
}

const getProducts = async (id) => {
    try {
        const requestPayload = {
            url: `${endpoints.orders}/${id}/details`,
            method: 'get',
        }
        const result = await request(requestPayload)
        Store.setProducts(id, result.response)
        await Store.initToShopping(id)
        return result
    } catch (err) {
        Store.showAlert('ERROR')
        return false
    }
}

const updateOrderById = async (id, payload) => {
    try {
        const requestPayload = {
            url: `${endpoints.orders}/${id}`,
            method: 'put',
            data: payload
        }
        const result = await request(requestPayload)
        return result
    } catch (err) {
        Store.showAlert('ERROR')
        return false
    }
}

const postCheckout = async (payload = {}) => {
    try {
        const requestPayload = {
            url: endpoints.checkout,
            method: 'post',
            data: [payload]
        }
        const result = await request(requestPayload)
        return result
    } catch (err) {
        Store.showAlert('ERROR')
        return false
    }
}

export {
    getLogin,
    getOrders,
    getOrderById,
    getProducts,
    updateOrderById,
    postCheckout
}
