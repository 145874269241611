module.exports = {
    CHANGE: "CHANGE",
    TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
    SHOW_ALERT: "SHOW_ALERT",
    SESSION: "SESSION",
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    REDIRECT: "REDIRECT",
    GOTO: "GOTO",
    SET_ORDERS: "SET_ORDERS",
    PRODUCTS: "PRODUCTS",
    TO_SHOPPING: "TO_SHOPPING",
    RECEIPT_CHECKOUT: "RECEIPT_CHECKOUT",
    SHOW_MODAL: "SHOW_MODAL",
    TODO: "TODO",
    OFFLINE: "OFFLINE"
  };
  