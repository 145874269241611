import React, { useContext, useRef } from 'react'
import { Card, CardBody, Button, InputGroup, FormInput, InputGroupAddon } from 'shards-react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'
import { FormattedMessage } from 'react-intl'

const ItemBag = ({value, cb}) => {
    const bagsRef = useRef(null)
    const appState = useContext(AppStore)

    const onClick = () => cb(bagsRef.current.value)

    // texts
    let texts = {
        "receipt_check.enter_number_of_bags": "Enter number of bags to be charged for",
        "receipt_check.save": "Save",
        "receipt_check.number_of_bags": "Number of bags"
    }

    Object.keys(texts).map((x) => texts[x] = <FormattedMessage id={x} defaultMessage={texts[x]}/>)
    // end texts

    const cardInput = (
        <Card className="mb-2">
            <CardContent>

                <Text className='mb-0'> {texts["receipt_check.enter_number_of_bags"]} </Text>
                <InputContainer>
                    <Input $state={appState.state} innerRef={bagsRef} type="number" placeholder="123" />
                    <InputGroupAddon type="append">
                        <ButtonSave $state={appState.state} onClick={onClick} theme="success"> {texts["receipt_check.save"]} </ButtonSave>
                    </InputGroupAddon>
                </InputContainer>

            </CardContent>
        </Card>
    )


    const cardCompleted = (
        <Card className="mb-2">
            <CardContent $completed>

                <Text $completed className='mb-0'> {texts["receipt_check.number_of_bags"]}: {value}</Text>
                <IconContainer $state={appState.state} className='p-2'>
                    <Icon $state={appState.state} className='material-icons'> check </Icon>
                </IconContainer>
                
            </CardContent>
        </Card>
    )

    if (value === '') return cardInput
    else return cardCompleted
}

const CardContent = styled(CardBody)`
    display: flex;
    flex-direction: column;
    // margin-right: 4px;
    flex: 1;
    align-items: center;
    padding: 14px !important;
    ${({$completed}) => 
        $completed && `
            flex-direction: row;
            align-items: normal;`
    }

`

const Text = styled.p`
    margin-top: -4px;

    ${({$completed}) => 
        $completed && `
            display: flex;
            flex: 1;`}
`

const InputContainer = styled(InputGroup)`
    max-width: 160px;
    margin: 8px 0 4px 0;
`
InputContainer.displayName = 'InputGroup'

const Input = styled(FormInput)`
    text-align: center;
    &:focus {
        border-color: ${({$state}) => $state.colors.primary} !important;
    }
`
Input.displayName = 'FormInput'

const ButtonSave = styled(Button)`
    background-color: ${({$state}) => $state.colors.primary} !important;
    border-color: ${({$state}) => $state.colors.primary} !important;
`

const IconContainer = styled.div`
    display: flex;
    width: 50px;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem !important;
    border-radius: 10p;
    margin: -14px -14px -14px 4px;
    border-top-right-radius: .625rem;
    border-bottom-right-radius: .625rem;    
    background-color: ${({$state}) => $state.colors.primary} !important;
`

const Icon = styled.i`
    font-weight: 900;
    margin-right: 4px; 
    font-size: 24px !important; 
    color: ${({$state}) => $state.colors.white} !important;
`

ItemBag.propTypes = {
    item: PropTypes.object
  }
  
  ItemBag.defaultProps = {
    item: {
        bags: 0,
        keep_bill: false
    }
  }

export default ItemBag
