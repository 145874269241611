import React, { useContext, useEffect, useState } from 'react'
import { Tabs, TabLink, TabContent } from 'react-tabs-redux'
import { Badge } from 'shards-react'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'
import ProductCard from './card'
import Empty from '../layout/Empty'
import Button from './button'
import useShopping from '@Hooks/useShopping'
import useApp from '@Hooks/useApp'
import { FormattedMessage } from 'react-intl'

const MainShopping = ({batch_id}) => {
    const appState = useContext(AppStore)
    const { setGoto } = useApp()

    const { orders, loadOrders, getOrderById } = useShopping()
    const [isLoading, setLoading] = useState(true)

    const [cardsToPick, setCardsToPick] = useState([])
    const [cardsNotFound, setCardsNotFound] = useState([])
    const [cardsFound, setCardsFound] = useState([])

    useEffect(() => {

        if (!orders.length) loadOrders()
        console.log(orders)
        
        const sortCards = (id, status) => {
            const items = getOrderById(id)?.products || []
            const itemsToPick = items.filter((item) => item.status === status)
            
            return itemsToPick.map((item, i) => {
                return <ProductCard item={item} key={i} />
            })
        }

        const result = orders.reduce((prev, curr) => {
            prev.to_pick = [...prev.to_pick, ...sortCards(curr.id, '')]
            prev.found = [...prev.found, ...sortCards(curr.id, 'found')]
            prev.not_found = [...prev.not_found, ...sortCards(curr.id, 'not_found')]
            return prev

        }, {to_pick: [], not_found: [], found: []})

        setCardsToPick(result.to_pick || [])
        setCardsFound(result.found || [])
        setCardsNotFound(result.not_found || [])
        console.log('---- end')
                

        setLoading(false)
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [batch_id, isLoading, orders])

    const onClick = () => setGoto('/receipt-check')

    const buildSection = (array) => orders.map((x, i) => {
        const cards = array.filter((card) => card.props.item.order_id === x.id)
        if (!cards.length) return null
        return (
            <div key={i} className="mb-4">
                <Title> {`${x.user.first_name} ${x.user.last_name}`} </Title>
                {cards}
            </div>
        )
    })

    const sectionToPick = buildSection(cardsToPick)
    const sectionFound = buildSection(cardsFound)
    const sectionNotFound = buildSection(cardsNotFound)

    // texts
    let texts = {
        "shopping.to_pick": "To-Pick",
        "shopping.in_review": "In Review",
        "shopping.done": "Done",
        "shopping.receipt_check": "Receipt Check",
        "shopping.empty.no_to_pick": "No more products",
        "shopping.empty.no_to_pick_subtitle": "You no have more products to shop",
        "shopping.empty.no_review": "No products reviewing",
        "shopping.empty.no_review_subtitle": "The products to review will appears here",
        "shopping.empty.no_done": "No products done",
        "shopping.empty.no_done_subtitle": "The products done will appears here"
    }

    Object.keys(texts).map((x) => texts[x] = <FormattedMessage id={x} defaultMessage={texts[x]}/>)
    // end texts

    if (isLoading) return <></>

    return (
        <TabsWrapper>
            <Tabs>
                <TabsContainer>
                    <TabItem to="tab1" $state={appState.state}>
                        <TabItemBody>
                            <Pill $state={appState.state} pill theme="light" className='mr-1'> { cardsToPick.length } </Pill> {texts["shopping.to_pick"]}
                            <div className="under_line" ></div>
                        </TabItemBody>
                    </TabItem>
                    <TabItem to="tab2" $state={appState.state}>
                        <TabItemBody>
                            <Pill $state={appState.state} pill theme="light" className='mr-1'> { cardsNotFound.length } </Pill> {texts["shopping.in_review"]}
                            <div className="under_line" ></div>
                        </TabItemBody>
                    </TabItem>
                    <TabItem to="tab3" $state={appState.state}>
                        <TabItemBody>
                            <Pill $state={appState.state} pill theme="light" className='mr-1'> { cardsFound.length } </Pill> {texts["shopping.done"]}
                            <div className="under_line" ></div>
                        </TabItemBody>
                    </TabItem>
                </TabsContainer>

                <TabItemContent for="tab1">
                    { cardsToPick.length ? sectionToPick : null}
                    { !cardsToPick.length ? <Button text={texts["shopping.receipt_check"]} ico="receipt_long" cb={onClick}/> : null }
                    { !cardsToPick.length && <Empty style={{height: 'calc(100vh - 170px - 130px)'}} title={texts["shopping.empty.no_to_pick"]} subtitle={texts["shopping.empty.no_to_pick_subtitle"]}/>}
                 </TabItemContent>
                <TabItemContent for="tab2">
                    { cardsNotFound.length ? sectionNotFound : null }
                    { !cardsToPick.length ? <Button text={texts["shopping.receipt_check"]} ico="receipt_long" cb={onClick}/> : null }
                    { !cardsNotFound.length && <Empty style={{height: 'calc(100vh - 170px - 130px)'}} title={texts["shopping.empty.no_review"]} subtitle={texts["shopping.empty.no_review_subtitle"]}/>}
                </TabItemContent>
                <TabItemContent for="tab3">
                    { cardsFound.length ? sectionFound : null }
                    { !cardsToPick.length ? <Button text={texts["shopping.receipt_check"]} ico="receipt_long" cb={onClick}/> : null }
                    { !cardsFound.length && <Empty style={{height: 'calc(100vh - 170px - 130px)'}} title={texts["shopping.empty.no_done"]} subtitle={texts["shopping.empty.no_done_subtitle"]}/>}
                </TabItemContent>
            </Tabs>
        </TabsWrapper>
    )
}

const TabsWrapper = styled.div`
    min-height: calc(100vh - 140px);
`

const TabsContainer = styled.div`
    display: flex;
    width: 100%;
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -16px;
    background-color: #FFF;
    position: fixed;
    z-index: 5;
    box-shadow: 0 0.125rem 0.625rem rgba(90,97,105,.12);
`

const TabItem = styled(TabLink)`
    display: flex;
    flex: 1;
    justify-content: center;
    background-color: #fff;
    border: none;
    padding: 8px 8px 0 8px;
    outline: none;
    transition: all ease .2s;

    &:focus {
        outline: none;
    };

    &.tab-link-active > div > div.under_line {
        background-color: ${({$state}) => $state.colors.primary} !important;
    };
`
TabItem.displayName = 'TabLink'

const TabItemBody = styled.div`
    width: 100%;
`

const TabItemContent = styled(TabContent)`
    padding: 16px 0px;
    padding-top: 60px !important;
    padding-bottom: 70px !important;
`
TabItemContent.displayName = 'TabContent'

const Pill = styled(Badge)`
    background-color: ${({$state}) => $state.colors.light_2} !important;
`

const Title = styled.p`
    font-size: 18px !important;
    font-weight: 700 !important;
    margin-bottom: 18px;
    padding: 0 10px;
`

export default MainShopping
