import React from "react"
import { Container, Row, Col } from "shards-react"
import AlertComponent from "@Components/layout/Alert"
import UICore from "@Components/core/core"
import useApp from "@Hooks/useApp"
import NotAllowed from "@Components/core/notAllowed"

const PublicLayout = ({ children }) => {
  const { compatible } = useApp()

  if (!compatible) return <NotAllowed />

  return (
    <UICore>
      <AlertComponent />
      <Container fluid >
        <Row>
          <Col
            className="p-0"
            sm="12">
            {children}
          </Col>
        </Row>
      </Container>
    </UICore>
  )
}

export default PublicLayout
