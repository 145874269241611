import React, { useContext } from 'react'
import { Button } from 'shards-react'
import PropTypes from 'prop-types'
import styles from '@Assets/styles/components/orderDetail.module.css'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'

const ButtonComponent = ({ text, ico, cb }) => {
    const appState = useContext(AppStore)
    return ( 
        <ButtonContainer onClick={cb} theme="light">
            <ButtonBody>
                <IconContainer $state={appState.state}>
                    <Icon $state={appState.state} className='material-icons'> {ico} </Icon>
                </IconContainer>
            </ButtonBody>
            <label className={styles.btn_label}> {text} </label>
        </ButtonContainer>
    )
}

const ButtonContainer = styled(Button)`
    max-width: 76px !important;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding-left: 8px !important;
    padding-right: 8px !important;
`

const ButtonBody = styled.div`
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
`

const IconContainer = styled.div`
    width: 50px;
    height: 50px;
    background-color: ${({$state}) => $state.colors.primary};
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 100%;
`

const Icon = styled.i`
    font-weight: 600;
    font-size: 1.3rem !important;
    color: ${({$state}) => $state.colors.white};
`

ButtonComponent.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    ico: PropTypes.string
  }
  
  ButtonComponent.defaultProps = {
    text: 'Button',
    ico: 'phone'
  }

export default ButtonComponent