import React, { useContext } from 'react'
import { Button } from 'shards-react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { AppStore } from '@Contexts/app'

const ButtonFC = ({ text, ico, cb }) => {
  const appState = useContext(AppStore)
    return ( 
      <ButtonContainer>
        <ButtonComponent $state={appState.state} theme="success" onClick={cb}>
            <Icon $state={appState.state} className='material-icons'>{ico}</Icon> {text}
        </ButtonComponent>
      </ButtonContainer>
    )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-items: center;
  position: fixed;
  bottom: 80px;
  left: 0px;
  width: 100%;

`

const buttonKeyframes = (color) => keyframes`
  from { box-shadow: none; }
  to { box-shadow: 0rem 1rem 10rem ${color}; }
`;

const ButtonComponent = styled(Button)`
  margin-left: 8px;
  padding: 9px !important;
  border-radius: 8px !important;

  border-color: ${({$state}) => $state.colors.primary} !important;
  background-color: ${({$state}) => $state.colors.primary} !important;

  width: 80%;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;

  animation: ${({$state}) => buttonKeyframes($state.colors.primary)} 1s linear infinite;
  animation-direction: alternate;
`

const Icon = styled.i`
  color: ${({$state}) => $state.colors.white};
  font-weight: 900;
  margin-right: 4px;
`

ButtonFC.propTypes = {
    text: PropTypes.string,
    ico: PropTypes.string
  }
  
  ButtonFC.defaultProps = {
    text: 'Button',
    ico: 'phone'
  }

export default ButtonFC