import { AppStore } from '@Contexts/app'
import React, { useContext } from 'react'
import styled from 'styled-components'

const Loading = () => {
  const appState = useContext(AppStore)

  return (
    <LoadingContainer>
      <Spiner $state={appState.state} className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </Spiner>
    </LoadingContainer>
  )
}

const LoadingContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  text-align: center;
`

const Spiner = styled.div`
  color: ${({$state}) => $state.colors.primary} !important;
`

export default Loading