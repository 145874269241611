class Product {
    id = ''
    order_id = ''
    price = ''
    quantity = ''
    name = ''
    image = ''
    status = ''
    has_option = false
    has_addons = false
    category = { id: '', name: ''}
    option = { id: '', name: '', price: '' }
    addons = [{ id: '', name: '', price: '' }]

    constructor (payload) { 
        console.log(payload)
        this.id = payload?.id || ''
        this.order_id = payload?.order_id || ''
        this.price = payload?.price || 0
        this.quantity = payload?.quantity || 0
        this.name = payload?.name || 0
        this.image = payload?.image_url || ''
        this.category = {
            id: payload?.category_id || '',
            name: payload?.category_name || ''
        }

        if (Object.keys(payload?.option || {}).length) {
            this.has_option = true
            this.option = {
                id: payload?.option.id || '',
                name: payload?.option.name || '',
                price: payload?.option.price || ''
            }
        }

        if (payload?.addons.length) {
            this.has_addons = true
            this.addons = payload?.addons.map((x) => ({
                id: x?.id || '',
                name: x?.name || '',
                price: x?.price || ''
            }))
        }

        return this
    }
}

export default Product
