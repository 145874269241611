export const mergeOrders = (currentOrders, incommingOrders) => {
    const oldOrders = currentOrders.reduce((prev, curr) => {
        prev[curr.id] = curr
        return prev
    }, {})

    const newOrders = incommingOrders?.reduce((prev, curr) => {
        prev[curr.id] = curr
        return prev
    }, {})

    let orders = {...oldOrders, ...newOrders}

    orders = Object.keys(orders).reduce((prev, curr) => {
        prev.push(orders[curr])
        return prev
    }, [])

    return orders
}

