import React from 'react'
import useApp from '@Hooks/useApp'
import { IntlProvider } from 'react-intl'
import en from '../../locale/en.json'
import es from '../../locale/es.json'

const languages = { en, es }

const Provider = ({ children }) => {
  const { lang } = useApp()
  const messages = languages[lang]

  return (
    <IntlProvider messages={messages} locale={lang} defaultLocale="en">
        {children}
    </IntlProvider>
  )
}

export { Provider }