import React, { useContext } from 'react'
import { Button } from 'shards-react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AppStore } from '@Contexts/app'

const ButtonFC = ({ text, ico, cb }) => {
  const appState = useContext(AppStore)
    return ( 
      <ButtonContainer>
        <ButtonComponent $state={appState.state} theme="success" onClick={cb}>
            <Icon className='material-icons'> {ico} </Icon> {text}
        </ButtonComponent>
      </ButtonContainer>
    )
}

const ButtonContainer = styled.div`
    display: flex;
    justify-items: center;
    position: fixed;
    bottom: 80px;
    left: 0px;
    width: 100%;
`

const ButtonComponent = styled(Button)`
    margin-left: 8px;
    padding: 9px !important;
    border-radius: 8px !important;


    background-color: ${({$state}) => $state.colors.primary} !important;

    width: 80%;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0.46875rem 2.1875rem rgba(0, 182, 36,.15), 0 0.9375rem 1.40625rem rgba(0, 182, 36,.15), 0 0.25rem 0.53125rem rgba(0, 182, 36,.17), 0 0.125rem 0.1875rem rgba(0, 182, 36,.15) !important;
`

const Icon = styled.i`
  font-size: 20px !important;
  font-weight: 700 !important;
`

ButtonFC.propTypes = {
    text: PropTypes.string,
    ico: PropTypes.string
  }
  
  ButtonFC.defaultProps = {
    text: 'Button',
    ico: 'phone'
  }

export default ButtonFC