import React, { useEffect } from 'react'
import { Alert } from 'shards-react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useApp from '@Hooks/useApp'

const AlertComponent = ({navbar}) => {
    // state
    const { alert, hideAlert } = useApp()
    const {type, title, visible} = alert//useState(Store.getAlert())
    
    // effect 
    useEffect(() => {
        if (visible) setTimeout(() => hideAlert(), 5000)
    }, [hideAlert, visible])

    // events
    const dismiss = () => hideAlert()

    return (
        <AlertContainer $navbar={navbar} dismissible={dismiss} open={visible} theme={type}>
            {title}
        </AlertContainer>
    ) 
}

const AlertContainer = styled(Alert)`
    text-align: center !important;
    z-index: 100 !important;
    width: 100% !important;
    
    ${({$navbar}) =>
        $navbar
        ? `margin-bottom: 0px !important;`
        : `position: absolute !important;`}
`

AlertComponent.propTypes = {
    navbar: PropTypes.bool,
    title: PropTypes.string,
    visible: PropTypes.bool,
    type: PropTypes.oneOf(['primary','success', 'danger'])
}

AlertComponent.defaultProps = {
    navbar: false,
    type: 'primary',
    title: 'Test Alert',
    visible: false
}

export default AlertComponent
