import React, { useContext, useRef, useState } from 'react'
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormCheckbox
} from 'shards-react'

import ButtonFC from './button';

import styled from 'styled-components'
import { AppStore } from '@Contexts/app'
import useAuth from '@Hooks/useAuth';
import { FormattedMessage } from 'react-intl';

const LoginForm = () => {
    const appState = useContext(AppStore)
    const { login } = useAuth()
    const [isLoading, setLoading] = useState(false)
    const [isChecked, setChecked] = useState(true)

    // refs
    const emailInput = useRef(null)
    const passwordInput = useRef(null)

    // Events
    const onClick = async (e) => {
        e.preventDefault()

        setLoading(true)
        const payload = {
            username: emailInput.current.value,
            password: passwordInput.current.value
        }

        const session = await login(payload)
        console.log(session)
        if (!session) {
            return setLoading(false)
        }
    }

    const onCheck = async (e) => {
        setChecked(!isChecked)
    }

    // texts
    let texts = {
        "login.email": "Email",
        "login.password": "Password",
        "login.remember": "Remember me",
        "login.login": "Login"
    }

    Object.keys(texts).map((x) => texts[x] = <FormattedMessage id={x} defaultMessage={texts[x]}/>)
    // end texts

    return (
        <ListGroup flush>
            <ListGroupItem className="p-3">
            <Row>
                <Col>
                <Form>
                    <Row form>
                    <Col md="12" sm="12" className="form-group">
                        <label htmlFor="feEmailAddress"> {texts["login.email"]} </label>
                        <Input $state={appState.state} innerRef={emailInput} type="email" placeholder="email@company.com" />
                    </Col>
                    <Col md="12" sm="12" className="form-group">
                        <label htmlFor="fePassword"> {texts["login.password"]} </label>
                        <Input $state={appState.state} innerRef={passwordInput} type="password" placeholder={'********'} />
                    </Col>
                    <Col md="12" sm="12" className="form-group">
                        <Checkbox $state={appState.state} checked={isChecked} onChange={onCheck}>
                            {texts["login.remember"]}
                        </Checkbox>
                    </Col>
                    </Row>
                        <ButtonFC onClick={onClick} isLoading={isLoading}> {texts["login.login"]} </ButtonFC>
                </Form>
                </Col>
            </Row>
            </ListGroupItem>
        </ListGroup>
    )
}

const Input = styled(FormInput)`
    ${({$state}) => `
        background-color: ${$state.colors.light_2} !important;
        border: 1px solid ${$state.colors.light_2} !important; 
    `}
`

const Checkbox = styled(FormCheckbox)`
    & > input:checked ~ label {
        &:before {
            background-color: ${({$state}) => $state.colors.primary} !important;
        }
    } 
`

export default LoginForm
